import React from "react";

export default function Inspried() {
  return (
    <div>
      <div className="feature-wrapper black-bg pt-100 pb-5">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-7 md-mb-3">
              <h2 className="display1-size dark-text lh-1 mb-0 text-gray-900 rajdhani-700">
                The most extensive DeFi product suite
              </h2>
              <p className=" my-4 dark-text text-gray-800 rajdhani-600 font-sm">
                Onmax is a Decentralized Finance (DeFi) protocol that provides
                extensive tools & services to streamline DeFi trading for
                seasonal traders and empower liquidity within the wide crypto
                spectrum.
              </p>
              <div className="d-flex flex-row gap-3 ">
                <a
                  href="#"
                  className="btn btn-lg text-white rajdhani-600 rounded-6 bg-gray-900 light-bg border-dark border-2 px-md-5 font-sm text-uppercase"
                >
                  Explore
                </a>
                {/* <a
                  href="#"
                  className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 px-md-5 font-sm text-uppercase"
                >
                  Join as creater
                </a> */}
              </div>
            </div>
            <div className="col-md-3 md-mt-3">
              <div className="d-flex flex-column mb-4">
                <h2 className="rajdhani-700 dark-text text-gray-900 lh-1 mb-0 display1-size">
                  ~28%
                </h2>
                <p className="mt-2 text-gray-800 dark-text rajdhani-600 font-sm mb-0">
                  APY
                </p>
              </div>
              <div className="d-flex flex-column mb-4 pt-md-2">
                <h2 className="rajdhani-700 dark-text text-gray-900 lh-1 mb-0 display1-size">
                  120+
                  {/* <i className="bi-star-fill text-warning font-sm ms-2" /> */}
                </h2>
                <p className="mt-2 text-gray-800 dark-text rajdhani-600 font-sm mb-0">
                  Liquidity Providers
                </p>
              </div>
              <div className="d-flex flex-column mb-4 pt-md-2">
                <h2 className="rajdhani-700 dark-text text-gray-900 lh-1 mb-0 display1-size">
                  1k+
                </h2>
                <p className="mt-2 text-gray-800 dark-text rajdhani-600 font-sm mb-0">
                  Governing Member
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
