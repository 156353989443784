import React from "react";

export default function GBlog() {
  return (
    <>
      <div className="feature-wrapper black-bg pb-50 pt-100">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <img
                src="assets/images/gov/start_casting_your_votes.svg"
                alt="banner"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 col-md-12 ms-auto md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 pe-lg-5">
              Start Casting Your Votes!
              </h2>
              <h6 className="text-gray-700 dark-text rajdhani-500 lh-30 pe-lg-5 me-lg-5">
              Grab OMtoken & start voting on governing proposals. Make sure that the Onmax Ecosystem is growing in the right way! 
              </h6>
            
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
