import React from "react";

export default function NFtBox() {
  return (
    <div>
      <div>
        <div className="feature-wrapper dark-bg3 pt-100 pb-100 posr border-bottom border-bluelight dark-border2">
          <div className="container">
            <div className="row">
              <div className="col-md-10">
                <h2 className="display1-size lh-2 mb-0 text-gray-900 rajdhani-600 dark-text">
                Acquire NFTs & unlock benefits within the Onmax ecosystem!

                </h2>
              </div>
              <div className="col-md-2 mt-auto text-md-end text-start mb-3 h-45 ovh posr">
                <i className="bi-arrow-up-right bg-gray-900 text-white h1 lh-32 m-0" />
              </div>
            </div>
          </div>
        </div>
        <div className="feature-wrapper style2 posr border-bottom border-bluelight dark-border2 dark-bg3">
          <div className="container">
            <div className="row ms-n3 me-n3">
              <div className="col-md-4 px-0">
                <div className="feature-div border-end border-start border-bluelight dark-border2">
                  <h4 className="display1-size text-gray-900 rajdhani-700 dark-text">
                    01
                  </h4>
                  <h2 className="rajdhani-600 dark-text h4 mt-2 mb-2">
                    {" "}
                    Access to Onmax Plus
                  </h2>
                  <p className="pe-3 text-gray-600 dark-text rajdhani-500 font-sm mb-0 pb-4">
                  Onmax NFT holders can use their NFTs to subscribe to Onmax Plus and gain access to these various trading tools. 
                  </p>
                </div>
              </div>
              <div className="col-md-4 px-0">
                <div className="feature-div border-end border-start border-bluelight dark-border2">
                  <h4 className="display1-size text-gray-900 rajdhani-700 dark-text">
                    02
                  </h4>
                  <h2 className="rajdhani-600 dark-text h4 mt-2 mb-2">
                  Community Gateway
                  </h2>
                  <p className="pe-3 text-gray-600 dark-text rajdhani-500 font-sm mb-0 pb-4">
                  Each Onmax NFT serves as a gateway to the Onmax community, offering holders exclusive access and special rewards.
                  </p>
                </div>
              </div>
              <div className="col-md-4 px-0">
                <div className="feature-div border-end border-start border-bluelight dark-border2">
                  <h4 className="display1-size text-gray-900 rajdhani-700 dark-text">
                    03
                  </h4>
                  <h2 className="rajdhani-600 dark-text h4 mt-2 mb-2">
                  Tailored Trading Bots
                  </h2>
                  <p className="pe-3 text-gray-600 dark-text rajdhani-500 font-sm mb-0">
                  Owning an NFT from the Onmax collections gives users access to trading bots, each designed for different risk and profit potentials. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
