
import React from 'react'

export default function GFaq() {
  return (
    <>
    <div className="faq-wrapper pt-100 black-bg">
  <div className="container pb-5">
    <div className="row">
      <div className="col-12 page-nav d-flex mb-5">
        <h2 className="text-gray-900 dark-text lh-2 rajdhani-600 h1">Frequently Asked Questions.</h2>
        <i className="bi-arrow-up-right text-gray-900 dark-text ms-auto h1" />
      </div>
      <div className="col-12">
        <div className="accordion accordion-flush rounded-10 ovh dark-card" id="accordionFlushExample1">
          <div className="accordion-item bg-transparent border-white dark-border">
            <h2 className="accordion-header" id="flush-headingOne">
              <button className="accordion-button ps-0 py-3 border-bottom border-white dark-border collapsed bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">1. What is OMand how do I get it?
                </span>
              </button>
            </h2>
            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body ps-0"><p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">OMis the native governance token of the Onmax Ecosystem. To participate in decision-making, you need to acquire OMtokens. These tokens allow you to propose, discuss, and vote on various governance topics to shape the ecosystem's future.</p></div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-white dark-border">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button className="accordion-button ps-0 py-3 border-bottom border-white dark-border bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">2. How can I propose a change within the Onmax Ecosystem?</span>
              </button>
            </h2>
            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body ps-0"><p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">To propose a change, you must stake a deposit of OMtokens and submit your proposal to the council. The proposal will then be discussed in the governing members' forum before being put up for a vote.</p></div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-white dark-border">
            <h2 className="accordion-header" id="flush-headingThree">
              <button className="accordion-button ps-0 py-3 border-bottom border-white dark-border bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">3. What is the role of discussions in Onmax governance?</span>
              </button>
            </h2>
            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body ps-0"><p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">Before voting, governing members participate in discussions within the community forum. These discussions allow members to review ongoing proposals and ensure they align with the Onmax council's constitution and development goals.


</p></div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-white dark-border">
            <h2 className="accordion-header" id="flush-headingFour">
              <button className="accordion-button ps-0 py-3 border-bottom border-white dark-border bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">4. How do I cast my vote on Onmax governance proposals?</span>
              </button>
            </h2>
            <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body ps-0"><p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">Once you hold OMtokens, you can cast your vote on live proposals that support the development and direction of the Onmax Ecosystem. Voting is crucial for community-driven governance decisions.</p></div>
            </div>
          </div>
          <div className="accordion-item bg-transparent border-white dark-border">
            <h2 className="accordion-header" id="flush-headingFive">
              <button className="accordion-button ps-0 py-3 border-bottom border-white dark-border bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">5. Can I join discussions if I don't want to propose changes?</span>
              </button>
            </h2>
            <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body ps-0"><p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">Yes! Even if you don't want to propose changes, you can still join discussions within the governing members' forum. This allows you to voice your opinion and engage with the community about ongoing proposals before the voting phase.</p></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="subscribe-banner rounded-10 mt-125 p-md-5 p-4 " style={{backgroundImage: 'url(assets/images/base.png)'}}>
          <div className="row">
            <div className="col-lg-6 col-md-6"><h2 className="h1 text-uppercase text-gray-900 rajdhani-700 lh-1 m-0">Subsribe to our <br />newsletter</h2></div>
            <div className="col-lg-5 col-md-6 md-mt-3 ms-auto mt-3">
              <form action="#">
                <div className="row">
                  <div className="col-8 pe-0"><input type="text" className="form-control form-control-lg bg-dark border-0 rounded-0" placeholder="Enter your email address" /></div>
                  <div className="col-4 ps-0"><button className="text-gray-900 text-uppercase rajdhani-700 font-sm form-control rounded-0 form-control-lg border-0 w-100">Submit</button></div>	
                </div>		
              </form>	
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}
