import React from 'react'
import Header from '../Coman/Header'
import AboutHero from './AboutHero'
import Misson from './Misson'
import AboutBox from './AboutBox'
import Footer from '../Coman/Footer'
import Journey from './Journey'

export default function AboutUs() {
  return (
    <div>
        <Header/>
        <AboutHero/>
        <AboutBox/>
        <Misson/>
        <Journey/>
        <Footer/>
    </div>
  )
}
