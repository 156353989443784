import React from 'react'

export default function Gmain() {
  return (
    <div>
        <div className="feature-wrapper p-100 pb-100 bg-darkblack style1 posr">
  <div className="nft-wrapper posa top-0 start-50 translate-middle w-100">
    <div className="container">
      <div className="row">
        {/* <div className="col-12">
          <div className="owl-carousel owl-theme four-nft owl-loaded owl-drag">
            <div className="owl-stage-outer"><div className="owl-stage" style={{transform: 'translate3d(-1650px, 0px, 0px)', transition: '0.25s', width: '3300px'}}><div className="owl-item cloned" style={{width: '245px', marginRight: '30px'}}><div className="item">
                    <div className="rounded-6 d-flex flex-column bg-white p-3">
                      <figure className="mb-0 w-100 rounded-10 h-200 bg-image-cover bg-no-repeat bg-image-center" style={{backgroundImage: 'url(assets/images/image-1.jpg)'}} />
                      <div className="d-flex mt-1 mb-0">
                        <a href="#" className="font-md ls-1 rajdhani-600 text-gray-900 mt-0 mb-2">Manchester City Football</a>
                      </div>
                      <div className="d-flex justify-content-between align-items-start gap-2">
                        <figure className="avater mb-0 me-0"><img src="assets/images/avater-1.png" alt="user" className="w-40" /></figure>
                        <div className="me-auto text-grey-500 text-start">
                          <h5 className="text-gray-900 rajdhani-700 font-sm text-uppercase lh-22 text-start m-0">Novita Thomson</h5>
                          <h6 className="text-gray-700 lh-1 mb-0 font-xs rajdhani-600">@nobita_thumbson</h6>
                        </div>
                        <div className="text-end">
                          <a href="#" className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-gray-100 font-sm text-uppercase add-to-save" style={{padding: '7px 11px'}}><i className="text-danger m-0 mt-1 font-sm bi-heart-fill" /></a>
                        </div>					        
                      </div>
                      <div className="d-flex mt-2 flex-column gap-2">
                        <h6 className="text-gray-900 mt-1 d-flex gap-1 justify-content-center w-100 lh-1 mb-0 rajdhani-700 bg-gray-200 py-3 rounded-6 h6 countdown1"><b className="d-none">22d</b><div className="simply-section simply-days-section"><div><span className="simply-amount">281</span><span className="simply-word">D : </span></div></div><div className="simply-section simply-hours-section"><div><span className="simply-amount">12</span><span className="simply-word">H : </span></div></div><div className="simply-section simply-minutes-section"><div><span className="simply-amount">54</span><span className="simply-word">M : </span></div></div><div className="simply-section simply-seconds-section"><div><span className="simply-amount">43</span><span className="simply-word">S</span></div></div></h6>
                        <a href="single-nft-1.html" className="btn btn-lg border-0 text-white rajdhani-600 ls-3 rounded-6 bg-gray-900 px-4 font-sm text-uppercase"> Start Bid</a>
                      </div>
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '245px', marginRight: '30px'}}><div className="item">
                    <div className="rounded-6 d-flex flex-column bg-lightyellow mt-3 p-3">
                      <figure className="mb-0 w-100 rounded-10 h-200 bg-image-cover bg-no-repeat bg-image-center" style={{backgroundImage: 'url(assets/images/image-3.jpg)'}} />
                      <div className="d-flex mt-1 mb-0">
                        <a href="#" className="font-md ls-1 rajdhani-600 text-gray-900 mt-0 mb-2">League Championship</a>
                      </div>
                      <div className="d-flex justify-content-between align-items-start gap-2">
                        <figure className="avater mb-0 me-0"><img src="assets/images/avater-2.png" alt="user" className="w-40" /></figure>
                        <div className="me-auto text-grey-500 text-start">
                          <h5 className="text-gray-900 rajdhani-700 font-sm text-uppercase lh-22 text-start m-0">Alice Hope</h5>
                          <h6 className="text-gray-700 lh-1 mb-0 font-xs rajdhani-600">@alice_hope</h6>
                        </div>
                        <div className="text-end">
                          <a href="#" className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-gray-100 font-sm text-uppercase add-to-save" style={{padding: '7px 11px'}}><i className="text-danger m-0 mt-1 bi-heart font-sm" /></a>
                        </div>					        
                      </div>
                      <div className="d-flex mt-2 flex-column gap-2">
                        <h6 className="text-gray-900 mt-1 d-flex gap-1 justify-content-center w-100 lh-1 mb-0 rajdhani-700 bg-white py-3 rounded-6 h6 countdown2"><b className="d-none">22D</b><div className="simply-section simply-days-section"><div><span className="simply-amount">0</span><span className="simply-word">D</span></div></div><div className="simply-section simply-hours-section"><div><span className="simply-amount">0</span><span className="simply-word">H</span></div></div><div className="simply-section simply-minutes-section"><div><span className="simply-amount">0</span><span className="simply-word">M</span></div></div><div className="simply-section simply-seconds-section"><div><span className="simply-amount">0</span><span className="simply-word">S</span></div></div></h6>
                        <a href="single-nft-1.html" className="btn btn-lg border-0 text-white rajdhani-600 ls-3 rounded-6 bg-gray-900 px-4 font-sm text-uppercase"> Start Bid</a>
                      </div>
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '245px', marginRight: '30px'}}><div className="item">
                    <div className="rounded-6 d-flex flex-column bg-lightpink mt-0 p-3">
                      <figure className="mb-0 w-100 rounded-10 h-200 bg-image-cover bg-no-repeat bg-image-center" style={{backgroundImage: 'url(assets/images/image-10.jpg)'}} />
                      <div className="d-flex mt-1 mb-0">
                        <a href="#" className="font-md ls-1 rajdhani-600 text-gray-900 mt-0 mb-2">Women x Man City</a>
                      </div>
                      <div className="d-flex justify-content-between align-items-start gap-2">
                        <figure className="avater mb-0 me-0"><img src="assets/images/avater-3.png" alt="user" className="w-40" /></figure>
                        <div className="me-auto text-grey-500 text-start">
                          <h5 className="text-gray-900 rajdhani-700 font-sm text-uppercase lh-22 text-start m-0">Peter Parker</h5>
                          <h6 className="text-gray-700 lh-1 mb-0 font-xs rajdhani-600">@peter_parker</h6>
                        </div>
                        <div className="text-end">
                          <a href="#" className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-gray-100 font-sm text-uppercase add-to-save" style={{padding: '7px 11px'}}><i className="text-danger m-0 mt-1 bi-heart font-sm" /></a>
                        </div>					        
                      </div>
                      <div className="d-flex mt-2 flex-column gap-2">
                        <h6 className="text-gray-900 mt-1 d-flex gap-1 justify-content-center w-100 lh-1 mb-0 rajdhani-700 bg-white py-3 rounded-6 h6 countdown3"><b className="d-none">22D</b><div className="simply-section simply-days-section"><div><span className="simply-amount">373</span><span className="simply-word">D : </span></div></div><div className="simply-section simply-hours-section"><div><span className="simply-amount">12</span><span className="simply-word">H : </span></div></div><div className="simply-section simply-minutes-section"><div><span className="simply-amount">54</span><span className="simply-word">M : </span></div></div><div className="simply-section simply-seconds-section"><div><span className="simply-amount">43</span><span className="simply-word">S</span></div></div></h6>
                        <a href="single-nft-1.html" className="btn btn-lg border-0 text-white rajdhani-600 ls-3 rounded-6 bg-gray-900 px-4 font-sm text-uppercase"> Start Bid</a>
                      </div>
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '245px', marginRight: '30px'}}><div className="item">
                    <div className="rounded-6 d-flex flex-column bg-instagram mt-2 p-3">
                      <figure className="mb-0 w-100 rounded-10 h-200 bg-image-cover bg-no-repeat bg-image-center" style={{backgroundImage: 'url(assets/images/image-8.jpg)'}} />
                      <div className="d-flex mt-1 mb-0">
                        <a href="#" className="font-md ls-1 rajdhani-600 text-white mt-0 mb-2">PUMA Pankhurst NFT</a>
                      </div>
                      <div className="d-flex justify-content-between align-items-start gap-2">
                        <figure className="avater mb-0 me-0"><img src="assets/images/team-7.png" alt="user" className="w-40" /></figure>
                        <div className="me-auto text-grey-500 text-start">
                          <h5 className="text-white rajdhani-700 font-sm text-uppercase lh-22 text-start m-0">Pandit Coast</h5>
                          <h6 className="text-gray-400 lh-1 mb-0 font-xs rajdhani-600">@medica_deo</h6>
                        </div>
                        <div className="text-end">
                          <a href="#" className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-gray-200 font-sm text-uppercase add-to-save" style={{padding: '7px 11px'}}><i className="text-danger m-0 mt-1 font-sm bi-heart-fill" /></a>
                        </div>					        
                      </div>
                      <div className="d-flex mt-2 flex-column gap-2">
                        <h6 className="text-gray-900 mt-1 d-flex gap-1 justify-content-center w-100 lh-1 mb-0 rajdhani-700 bg-white py-3 rounded-6 h6 countdown3"><b className="d-none">22D</b><div className="simply-section simply-days-section"><div><span className="simply-amount">373</span><span className="simply-word">D : </span></div></div><div className="simply-section simply-hours-section"><div><span className="simply-amount">12</span><span className="simply-word">H : </span></div></div><div className="simply-section simply-minutes-section"><div><span className="simply-amount">54</span><span className="simply-word">M : </span></div></div><div className="simply-section simply-seconds-section"><div><span className="simply-amount">43</span><span className="simply-word">S</span></div></div></h6>
                        <a href="single-nft-1.html" className="btn btn-lg border-0 text-white rajdhani-600 ls-3 rounded-6 bg-gray-900 px-4 font-sm text-uppercase"> Start Bid</a>
                      </div>
                    </div>
                  </div></div><div className="owl-item" style={{width: '245px', marginRight: '30px'}}><div className="item">
                    <div className="rounded-6 d-flex flex-column bg-white p-3">
                      <figure className="mb-0 w-100 rounded-10 h-200 bg-image-cover bg-no-repeat bg-image-center" style={{backgroundImage: 'url(assets/images/image-1.jpg)'}} />
                      <div className="d-flex mt-1 mb-0">
                        <a href="#" className="font-md ls-1 rajdhani-600 text-gray-900 mt-0 mb-2">Manchester City Football</a>
                      </div>
                      <div className="d-flex justify-content-between align-items-start gap-2">
                        <figure className="avater mb-0 me-0"><img src="assets/images/avater-1.png" alt="user" className="w-40" /></figure>
                        <div className="me-auto text-grey-500 text-start">
                          <h5 className="text-gray-900 rajdhani-700 font-sm text-uppercase lh-22 text-start m-0">Novita Thomson</h5>
                          <h6 className="text-gray-700 lh-1 mb-0 font-xs rajdhani-600">@nobita_thumbson</h6>
                        </div>
                        <div className="text-end">
                          <a href="#" className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-gray-100 font-sm text-uppercase add-to-save" style={{padding: '7px 11px'}}><i className="text-danger m-0 mt-1 font-sm bi-heart-fill" /></a>
                        </div>					        
                      </div>
                      <div className="d-flex mt-2 flex-column gap-2">
                        <h6 className="text-gray-900 mt-1 d-flex gap-1 justify-content-center w-100 lh-1 mb-0 rajdhani-700 bg-gray-200 py-3 rounded-6 h6 countdown1"><b className="d-none">22d</b><div className="simply-section simply-days-section"><div><span className="simply-amount">281</span><span className="simply-word">D : </span></div></div><div className="simply-section simply-hours-section"><div><span className="simply-amount">12</span><span className="simply-word">H : </span></div></div><div className="simply-section simply-minutes-section"><div><span className="simply-amount">54</span><span className="simply-word">M : </span></div></div><div className="simply-section simply-seconds-section"><div><span className="simply-amount">43</span><span className="simply-word">S</span></div></div></h6>
                        <a href="single-nft-1.html" className="btn btn-lg border-0 text-white rajdhani-600 ls-3 rounded-6 bg-gray-900 px-4 font-sm text-uppercase"> Start Bid</a>
                      </div>
                    </div>
                  </div></div><div className="owl-item" style={{width: '245px', marginRight: '30px'}}><div className="item">
                    <div className="rounded-6 d-flex flex-column bg-lightyellow mt-3 p-3">
                      <figure className="mb-0 w-100 rounded-10 h-200 bg-image-cover bg-no-repeat bg-image-center" style={{backgroundImage: 'url(assets/images/image-3.jpg)'}} />
                      <div className="d-flex mt-1 mb-0">
                        <a href="#" className="font-md ls-1 rajdhani-600 text-gray-900 mt-0 mb-2">League Championship</a>
                      </div>
                      <div className="d-flex justify-content-between align-items-start gap-2">
                        <figure className="avater mb-0 me-0"><img src="assets/images/avater-2.png" alt="user" className="w-40" /></figure>
                        <div className="me-auto text-grey-500 text-start">
                          <h5 className="text-gray-900 rajdhani-700 font-sm text-uppercase lh-22 text-start m-0">Alice Hope</h5>
                          <h6 className="text-gray-700 lh-1 mb-0 font-xs rajdhani-600">@alice_hope</h6>
                        </div>
                        <div className="text-end">
                          <a href="#" className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-gray-100 font-sm text-uppercase add-to-save" style={{padding: '7px 11px'}}><i className="text-danger m-0 mt-1 bi-heart font-sm" /></a>
                        </div>					        
                      </div>
                      <div className="d-flex mt-2 flex-column gap-2">
                        <h6 className="text-gray-900 mt-1 d-flex gap-1 justify-content-center w-100 lh-1 mb-0 rajdhani-700 bg-white py-3 rounded-6 h6 countdown2"><b className="d-none">22D</b><div className="simply-section simply-days-section"><div><span className="simply-amount">0</span><span className="simply-word">D</span></div></div><div className="simply-section simply-hours-section"><div><span className="simply-amount">0</span><span className="simply-word">H</span></div></div><div className="simply-section simply-minutes-section"><div><span className="simply-amount">0</span><span className="simply-word">M</span></div></div><div className="simply-section simply-seconds-section"><div><span className="simply-amount">0</span><span className="simply-word">S</span></div></div></h6>
                        <a href="single-nft-1.html" className="btn btn-lg border-0 text-white rajdhani-600 ls-3 rounded-6 bg-gray-900 px-4 font-sm text-uppercase"> Start Bid</a>
                      </div>
                    </div>
                  </div></div><div className="owl-item active" style={{width: '245px', marginRight: '30px'}}><div className="item">
                    <div className="rounded-6 d-flex flex-column bg-lightpink mt-0 p-3">
                      <figure className="mb-0 w-100 rounded-10 h-200 bg-image-cover bg-no-repeat bg-image-center" style={{backgroundImage: 'url(assets/images/image-10.jpg)'}} />
                      <div className="d-flex mt-1 mb-0">
                        <a href="#" className="font-md ls-1 rajdhani-600 text-gray-900 mt-0 mb-2">Women x Man City</a>
                      </div>
                      <div className="d-flex justify-content-between align-items-start gap-2">
                        <figure className="avater mb-0 me-0"><img src="assets/images/avater-3.png" alt="user" className="w-40" /></figure>
                        <div className="me-auto text-grey-500 text-start">
                          <h5 className="text-gray-900 rajdhani-700 font-sm text-uppercase lh-22 text-start m-0">Peter Parker</h5>
                          <h6 className="text-gray-700 lh-1 mb-0 font-xs rajdhani-600">@peter_parker</h6>
                        </div>
                        <div className="text-end">
                          <a href="#" className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-gray-100 font-sm text-uppercase add-to-save" style={{padding: '7px 11px'}}><i className="text-danger m-0 mt-1 bi-heart font-sm" /></a>
                        </div>					        
                      </div>
                      <div className="d-flex mt-2 flex-column gap-2">
                        <h6 className="text-gray-900 mt-1 d-flex gap-1 justify-content-center w-100 lh-1 mb-0 rajdhani-700 bg-white py-3 rounded-6 h6 countdown3"><b className="d-none">22D</b><div className="simply-section simply-days-section"><div><span className="simply-amount">373</span><span className="simply-word">D : </span></div></div><div className="simply-section simply-hours-section"><div><span className="simply-amount">12</span><span className="simply-word">H : </span></div></div><div className="simply-section simply-minutes-section"><div><span className="simply-amount">54</span><span className="simply-word">M : </span></div></div><div className="simply-section simply-seconds-section"><div><span className="simply-amount">43</span><span className="simply-word">S</span></div></div></h6>
                        <a href="single-nft-1.html" className="btn btn-lg border-0 text-white rajdhani-600 ls-3 rounded-6 bg-gray-900 px-4 font-sm text-uppercase"> Start Bid</a>
                      </div>
                    </div>
                  </div></div><div className="owl-item active" style={{width: '245px', marginRight: '30px'}}><div className="item">
                    <div className="rounded-6 d-flex flex-column bg-instagram mt-2 p-3">
                      <figure className="mb-0 w-100 rounded-10 h-200 bg-image-cover bg-no-repeat bg-image-center" style={{backgroundImage: 'url(assets/images/image-8.jpg)'}} />
                      <div className="d-flex mt-1 mb-0">
                        <a href="#" className="font-md ls-1 rajdhani-600 text-white mt-0 mb-2">PUMA Pankhurst NFT</a>
                      </div>
                      <div className="d-flex justify-content-between align-items-start gap-2">
                        <figure className="avater mb-0 me-0"><img src="assets/images/team-7.png" alt="user" className="w-40" /></figure>
                        <div className="me-auto text-grey-500 text-start">
                          <h5 className="text-white rajdhani-700 font-sm text-uppercase lh-22 text-start m-0">Pandit Coast</h5>
                          <h6 className="text-gray-400 lh-1 mb-0 font-xs rajdhani-600">@medica_deo</h6>
                        </div>
                        <div className="text-end">
                          <a href="#" className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-gray-200 font-sm text-uppercase add-to-save" style={{padding: '7px 11px'}}><i className="text-danger m-0 mt-1 font-sm bi-heart-fill" /></a>
                        </div>					        
                      </div>
                      <div className="d-flex mt-2 flex-column gap-2">
                        <h6 className="text-gray-900 mt-1 d-flex gap-1 justify-content-center w-100 lh-1 mb-0 rajdhani-700 bg-white py-3 rounded-6 h6 countdown3"><b className="d-none">22D</b><div className="simply-section simply-days-section"><div><span className="simply-amount">373</span><span className="simply-word">D : </span></div></div><div className="simply-section simply-hours-section"><div><span className="simply-amount">12</span><span className="simply-word">H : </span></div></div><div className="simply-section simply-minutes-section"><div><span className="simply-amount">54</span><span className="simply-word">M : </span></div></div><div className="simply-section simply-seconds-section"><div><span className="simply-amount">43</span><span className="simply-word">S</span></div></div></h6>
                        <a href="single-nft-1.html" className="btn btn-lg border-0 text-white rajdhani-600 ls-3 rounded-6 bg-gray-900 px-4 font-sm text-uppercase"> Start Bid</a>
                      </div>
                    </div>
                  </div></div><div className="owl-item cloned active" style={{width: '245px', marginRight: '30px'}}><div className="item">
                    <div className="rounded-6 d-flex flex-column bg-white p-3">
                      <figure className="mb-0 w-100 rounded-10 h-200 bg-image-cover bg-no-repeat bg-image-center" style={{backgroundImage: 'url(assets/images/image-1.jpg)'}} />
                      <div className="d-flex mt-1 mb-0">
                        <a href="#" className="font-md ls-1 rajdhani-600 text-gray-900 mt-0 mb-2">Manchester City Football</a>
                      </div>
                      <div className="d-flex justify-content-between align-items-start gap-2">
                        <figure className="avater mb-0 me-0"><img src="assets/images/avater-1.png" alt="user" className="w-40" /></figure>
                        <div className="me-auto text-grey-500 text-start">
                          <h5 className="text-gray-900 rajdhani-700 font-sm text-uppercase lh-22 text-start m-0">Novita Thomson</h5>
                          <h6 className="text-gray-700 lh-1 mb-0 font-xs rajdhani-600">@nobita_thumbson</h6>
                        </div>
                        <div className="text-end">
                          <a href="#" className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-gray-100 font-sm text-uppercase add-to-save" style={{padding: '7px 11px'}}><i className="text-danger m-0 mt-1 font-sm bi-heart-fill" /></a>
                        </div>					        
                      </div>
                      <div className="d-flex mt-2 flex-column gap-2">
                        <h6 className="text-gray-900 mt-1 d-flex gap-1 justify-content-center w-100 lh-1 mb-0 rajdhani-700 bg-gray-200 py-3 rounded-6 h6 countdown1"><b className="d-none">22d</b><div className="simply-section simply-days-section"><div><span className="simply-amount">281</span><span className="simply-word">D : </span></div></div><div className="simply-section simply-hours-section"><div><span className="simply-amount">12</span><span className="simply-word">H : </span></div></div><div className="simply-section simply-minutes-section"><div><span className="simply-amount">54</span><span className="simply-word">M : </span></div></div><div className="simply-section simply-seconds-section"><div><span className="simply-amount">43</span><span className="simply-word">S</span></div></div></h6>
                        <a href="single-nft-1.html" className="btn btn-lg border-0 text-white rajdhani-600 ls-3 rounded-6 bg-gray-900 px-4 font-sm text-uppercase"> Start Bid</a>
                      </div>
                    </div>
                  </div></div><div className="owl-item cloned active" style={{width: '245px', marginRight: '30px'}}><div className="item">
                    <div className="rounded-6 d-flex flex-column bg-lightyellow mt-3 p-3">
                      <figure className="mb-0 w-100 rounded-10 h-200 bg-image-cover bg-no-repeat bg-image-center" style={{backgroundImage: 'url(assets/images/image-3.jpg)'}} />
                      <div className="d-flex mt-1 mb-0">
                        <a href="#" className="font-md ls-1 rajdhani-600 text-gray-900 mt-0 mb-2">League Championship</a>
                      </div>
                      <div className="d-flex justify-content-between align-items-start gap-2">
                        <figure className="avater mb-0 me-0"><img src="assets/images/avater-2.png" alt="user" className="w-40" /></figure>
                        <div className="me-auto text-grey-500 text-start">
                          <h5 className="text-gray-900 rajdhani-700 font-sm text-uppercase lh-22 text-start m-0">Alice Hope</h5>
                          <h6 className="text-gray-700 lh-1 mb-0 font-xs rajdhani-600">@alice_hope</h6>
                        </div>
                        <div className="text-end">
                          <a href="#" className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-gray-100 font-sm text-uppercase add-to-save" style={{padding: '7px 11px'}}><i className="text-danger m-0 mt-1 bi-heart font-sm" /></a>
                        </div>					        
                      </div>
                      <div className="d-flex mt-2 flex-column gap-2">
                        <h6 className="text-gray-900 mt-1 d-flex gap-1 justify-content-center w-100 lh-1 mb-0 rajdhani-700 bg-white py-3 rounded-6 h6 countdown2"><b className="d-none">22D</b><div className="simply-section simply-days-section"><div><span className="simply-amount">0</span><span className="simply-word">D</span></div></div><div className="simply-section simply-hours-section"><div><span className="simply-amount">0</span><span className="simply-word">H</span></div></div><div className="simply-section simply-minutes-section"><div><span className="simply-amount">0</span><span className="simply-word">M</span></div></div><div className="simply-section simply-seconds-section"><div><span className="simply-amount">0</span><span className="simply-word">S</span></div></div></h6>
                        <a href="single-nft-1.html" className="btn btn-lg border-0 text-white rajdhani-600 ls-3 rounded-6 bg-gray-900 px-4 font-sm text-uppercase"> Start Bid</a>
                      </div>
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '245px', marginRight: '30px'}}><div className="item">
                    <div className="rounded-6 d-flex flex-column bg-lightpink mt-0 p-3">
                      <figure className="mb-0 w-100 rounded-10 h-200 bg-image-cover bg-no-repeat bg-image-center" style={{backgroundImage: 'url(assets/images/image-10.jpg)'}} />
                      <div className="d-flex mt-1 mb-0">
                        <a href="#" className="font-md ls-1 rajdhani-600 text-gray-900 mt-0 mb-2">Women x Man City</a>
                      </div>
                      <div className="d-flex justify-content-between align-items-start gap-2">
                        <figure className="avater mb-0 me-0"><img src="assets/images/avater-3.png" alt="user" className="w-40" /></figure>
                        <div className="me-auto text-grey-500 text-start">
                          <h5 className="text-gray-900 rajdhani-700 font-sm text-uppercase lh-22 text-start m-0">Peter Parker</h5>
                          <h6 className="text-gray-700 lh-1 mb-0 font-xs rajdhani-600">@peter_parker</h6>
                        </div>
                        <div className="text-end">
                          <a href="#" className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-gray-100 font-sm text-uppercase add-to-save" style={{padding: '7px 11px'}}><i className="text-danger m-0 mt-1 bi-heart font-sm" /></a>
                        </div>					        
                      </div>
                      <div className="d-flex mt-2 flex-column gap-2">
                        <h6 className="text-gray-900 mt-1 d-flex gap-1 justify-content-center w-100 lh-1 mb-0 rajdhani-700 bg-white py-3 rounded-6 h6 countdown3"><b className="d-none">22D</b><div className="simply-section simply-days-section"><div><span className="simply-amount">373</span><span className="simply-word">D : </span></div></div><div className="simply-section simply-hours-section"><div><span className="simply-amount">12</span><span className="simply-word">H : </span></div></div><div className="simply-section simply-minutes-section"><div><span className="simply-amount">54</span><span className="simply-word">M : </span></div></div><div className="simply-section simply-seconds-section"><div><span className="simply-amount">43</span><span className="simply-word">S</span></div></div></h6>
                        <a href="single-nft-1.html" className="btn btn-lg border-0 text-white rajdhani-600 ls-3 rounded-6 bg-gray-900 px-4 font-sm text-uppercase"> Start Bid</a>
                      </div>
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '245px', marginRight: '30px'}}><div className="item">
                    <div className="rounded-6 d-flex flex-column bg-instagram mt-2 p-3">
                      <figure className="mb-0 w-100 rounded-10 h-200 bg-image-cover bg-no-repeat bg-image-center" style={{backgroundImage: 'url(assets/images/image-8.jpg)'}} />
                      <div className="d-flex mt-1 mb-0">
                        <a href="#" className="font-md ls-1 rajdhani-600 text-white mt-0 mb-2">PUMA Pankhurst NFT</a>
                      </div>
                      <div className="d-flex justify-content-between align-items-start gap-2">
                        <figure className="avater mb-0 me-0"><img src="assets/images/team-7.png" alt="user" className="w-40" /></figure>
                        <div className="me-auto text-grey-500 text-start">
                          <h5 className="text-white rajdhani-700 font-sm text-uppercase lh-22 text-start m-0">Pandit Coast</h5>
                          <h6 className="text-gray-400 lh-1 mb-0 font-xs rajdhani-600">@medica_deo</h6>
                        </div>
                        <div className="text-end">
                          <a href="#" className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-gray-200 font-sm text-uppercase add-to-save" style={{padding: '7px 11px'}}><i className="text-danger m-0 mt-1 font-sm bi-heart-fill" /></a>
                        </div>					        
                      </div>
                      <div className="d-flex mt-2 flex-column gap-2">
                        <h6 className="text-gray-900 mt-1 d-flex gap-1 justify-content-center w-100 lh-1 mb-0 rajdhani-700 bg-white py-3 rounded-6 h6 countdown3"><b className="d-none">22D</b><div className="simply-section simply-days-section"><div><span className="simply-amount">373</span><span className="simply-word">D : </span></div></div><div className="simply-section simply-hours-section"><div><span className="simply-amount">12</span><span className="simply-word">H : </span></div></div><div className="simply-section simply-minutes-section"><div><span className="simply-amount">54</span><span className="simply-word">M : </span></div></div><div className="simply-section simply-seconds-section"><div><span className="simply-amount">43</span><span className="simply-word">S</span></div></div></h6>
                        <a href="single-nft-1.html" className="btn btn-lg border-0 text-white rajdhani-600 ls-3 rounded-6 bg-gray-900 px-4 font-sm text-uppercase"> Start Bid</a>
                      </div>
                    </div>
                  </div></div></div></div><div className="owl-nav disabled"><button type="button" role="presentation" className="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" className="owl-next"><span aria-label="Next">›</span></button></div><div className="owl-dots disabled" /></div>
        </div> */}
      </div>
    </div>
  </div>
  <div className="container">
    <div className="row">
      <div className="col-md-10 pb-50 pt-5"><h2 className="display1-size lh-2 text-white rajdhani-500">Get started now!</h2></div>
    </div>
    <div className="row">
      <div className="col-md-4">
        <div className="feature-div border-dark border-end rounded-6">
          <img src="assets/images/gov/join_discussion_final_icon.gif" width={80} alt="box" className="ms-n1 mb-4" />
          <h2 className="rajdhani-500 h3 mt-2">Join Discussion </h2>
          <p className="pe-3 rajdhani-500 font-sm mb-0">Discuss ongoing proposals with governing members whether it aligns with the council constitution. </p>
          <i className="bi-arrow-down-right h1 mt-4 text-gray-900 lh-1" />
        </div>
      </div>
      <div className="col-md-4">
        <div className="feature-div border-dark border-end rounded-6">
        <img src="assets/images/gov/propose_change_final_icon.gif" width={80} alt="box" className="ms-n1 mb-4" />
        <h2 className="rajdhani-500 h3 mt-2">Propose Changes </h2>
          <p className="pe-3 rajdhani-500 font-sm mb-0">Send a proposed suggestion to the forum or directly pull a proposal  by staking OMtokens. </p>
          <i className="bi-arrow-down-right h1 mt-4 text-gray-900 lh-1" />
        </div>
      </div>
      <div className="col-md-4">
        <div className="feature-div rounded-6">
        <img src="assets/images/gov/vote_final_icon.gif" width={80} alt="box" className="ms-n1 mb-4" />
          <h2 className="rajdhani-500 h3 mt-2">Use OM, Vote Now</h2>
          <p className="pe-3 rajdhani-500 font-sm mb-0">Use your OMtokens to cast votes on live proposals within the Onmax governance.  
          </p>
          <i className="bi-arrow-down-right h1 mt-4 text-gray-900 lh-1" />
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
