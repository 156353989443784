import React from "react";

export default function NftsHero() {
  return (
    <div>
      <div
        className="banner-wrapper style17 pt--lg-100 bg-image-cover dark-bg3 bg-image-center bg-no-repeat"
        style={{ backgroundImage: "url(assets/images/nft/bg-371.png)" }}
      >
        <div className="container-large pt-4 pb-lg-5">
          <div className="row pt-2 pb-lg-4 pb-2 justify-content-between">
            <div className="col-12 posr">
              {/* <img
                src="assets/images/round-text-2.png"
                alt="icon"
                className="w-110 h-110 posa right-0 top-0"
              /> */}
              <h2 className="display9-size lh-1 rajdhani-700 text-gray-900 dark-textwhite">
              Unique NFTs with Multiple Utilities!
              </h2>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-xl-3 d-none d-xl-block">
              <div className="d-flex justify-content-start">
                <h4 className="rajdhani-600 mt-1 dark-text h6 lh-24 text-gray-900">
                  Connect <br />
                  more people
                </h4>
                <figure className="w-51 mb-0 ms-4">
                  <img
                    src="assets/images/avater-4.png"
                    alt="avater"
                    className="w-100"
                  />
                </figure>
                <figure className="w-51 mb-0 ms-n4">
                  <img
                    src="assets/images/avater-3.png"
                    alt="avater"
                    className="w-100"
                  />
                </figure>
                <figure className="w-51 mb-0 ms-n4">
                  <img
                    src="assets/images/avater-2.png"
                    alt="avater"
                    className="w-100"
                  />
                </figure>
                <figure className="w-51 mb-0 ms-n4">
                  <img src="assets/images/n-2.png" alt="avater" className="w-100" />
                </figure>
              </div>
            </div> */}
            {/* <div className="col-xl-4 col-lg-5 col-md-5 md-mb-3 md-mt-3">
              <div className="d-flex justify-content-start gap-2">
                <h2 className="rajdhani-700 dark-text text-gray-900 lh-1 mb-0 display2-size">
                  4.8
                </h2>
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row gap-1 mt-1">
                    <i className="bi-star-fill text-warning font-sm" />
                    <i className="bi-star-fill text-warning font-sm" />
                    <i className="bi-star-fill text-warning font-sm" />
                    <i className="bi-star-fill text-warning font-sm" />
                    <i className="bi-star-half text-warning font-sm" />
                  </div>
                  <h4 className="rajdhani-600 dark-text font-md lh-18 text-gray-900">
                    32k people feedback
                  </h4>
                </div>
              </div>
            </div> */}
            {/* <div className="col-xl-5 col-lg-7 col-md-7"> */}
              <div className="d-flex flex-row gap-3">
                <a
                  href="#"
                  className="btn btn-lg text-white rajdhani-600 rounded-6 bg-gray-900 border-dark border-2 px-lg-5 font-sm text-uppercase"
                >
                  Explore NFTs
                </a>
                {/* <a
                  href="#"
                  className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 px-lg-5 font-sm text-uppercase"
                >
                   Explore NFTs
                </a> */}
              </div>
            {/* </div> */}
          </div>
          <div className="row pt-lg-5 mt-3 pt-3">
            <div className="col-lg-4 col-md-4 md-mb-4">
              <div
                className="d-flex flex-column w-100 rounded-25 ovh"
                style={{
                  background: "linear-gradient(-20deg, #75D6A1, #E6F48D)",

                }}
              >
               
                <video
                playsinline="playsinline"
                muted="muted"
                preload="yes"
                autoplay="autoplay"
                loop="loop"
                id="vjs_video_739_html5_api"
                class="video-js w-100 h-100"
                data-setup='{"autoplay":"any"}'
              >
                <source src="assets/images/nft/nft1.mp4" type="video/mp4" />
              </video>
              
              </div>
            </div>
            <div className="col-lg-4 col-md-4 md-mb-4">
              <div
                className="d-flex flex-column w-100 rounded-25 ovh"
                style={{
                  background: "linear-gradient(-20deg, #75D6A1, #E6F48D)",

                }}
              >
               
                <video
                playsinline="playsinline"
                muted="muted"
                preload="yes"
                autoplay="autoplay"
                loop="loop"
                id="vjs_video_739_html5_api"
                class="video-js w-100 h-100"
                data-setup='{"autoplay":"any"}'
              >
                <source src="assets/images/nft/nft2.mp4" type="video/mp4" />
              </video>
              
              </div>
            </div>
            <div className="col-lg-4 col-md-4 md-mb-4">
              <div
                className="d-flex flex-column w-100 rounded-25 ovh"
                style={{
                  background: "linear-gradient(-20deg, #75D6A1, #E6F48D)",

                }}
              >
               
                <video
                playsinline="playsinline"
                muted="muted"
                preload="yes"
                autoplay="autoplay"
                loop="loop"
                id="vjs_video_739_html5_api"
                class="video-js w-100 h-100"
                data-setup='{"autoplay":"any"}'
              >
                <source src="assets/images/nft/nft3.mp4" type="video/mp4" />
              </video>
              
              </div>
            </div>
   
          </div>
        </div>
      </div>
    </div>
  );
}
