import React from "react";

export default function TraBot() {
  return (
    <div>
      <div className="feature-wrap py-5 pt-50">
        <div className="container py-lg-4">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h2 className="rajdhani-700 h1 text-center mb-4 text-gray-900 dark-text">
                Serving all-your needs for Automated Trading
              </h2>
              <p className="text-gray-800 px-lg-4 mb-4 text-center rajdhani-600 font-sm mb-3 dark-text">
                By leveraging latest trading algorithms, Onmax bots provide
                users with data-driven insights, optimize trades in real-time,
                and handle multiple trades simultaneously. This allows both
                novice and experienced traders to maximize their returns with
                minimal effort, making Onmax a user-friendly platform for
                decentralized finance.
              </p>
              <p className="text-gray-800 px-lg-5 mb-5 text-center rajdhani-600 font-sm mb-3 dark-text">
                The YellowFox, BrownFox, and BlackFox bots automate complex
                trading processes, ensuring that users can execute strategies
                like arbitrage or liquidity farming without needing to
                constantly monitor the markets.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-3 text-center">
              <div className="btn-icon btn-lg mx-auto btn new-pink-bg rounded-pill m-0 font-sm text-white">
                <img src="assets/images/tra/trading_volume_generated.svg" height={30} width={30} alt="" />
              </div>
              <h2 className="rajdhani-700 h1 mt-2">
                $1M <span className="font-xl">+</span>
              </h2>
              <p className="text-gray-800 px-lg-4 mb-3 text-center rajdhani-600 font-sm mb-0 dark-text">
                Trading Volume Generated
              </p>
            </div>
            <div className="col-md-3 text-center">
            <div className="btn-icon btn-lg mx-auto btn new-pink-bg rounded-pill m-0 font-sm text-white">
                <img src="assets/images/tra/trades_executed.svg" height={30} width={30} alt="" />
              </div>
              <h2 className="rajdhani-700 h1 mt-2">
                50k<span className="font-xl">+ </span>
              </h2>
              <p className="text-gray-800 px-lg-4 mb-3 text-center rajdhani-600 font-sm mb-0 dark-text">
                Trades Executed
              </p>
            </div>
            <div className="col-md-3 text-center">
            <div className="btn-icon btn-lg mx-auto btn new-pink-bg rounded-pill m-0 font-sm text-white">
                <img src="assets/images/tra/average_profit_ratio.svg" height={30} width={30} alt="" />
              </div>
              <h2 className="rajdhani-700 h1 mt-2">
                1.8<span className="font-xl">x</span>
              </h2>
              <p className="text-gray-800 px-lg-4 mb-3 text-center rajdhani-600 font-sm mb-0 dark-text">
                Average Profit Ratio
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
