import React from "react";

export default function Rodemap() {
  return (
    <div>
      <div className="roadmap-wrapper pt-5">
        <div className="container pt-lg-4">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10 text-center page-nav">
              <h2 className="text-gray-900 dark-text lh-2 mb-2 mt-0 rajdhani-600 h1">
                Roadmap
              </h2>
              {/* <p className="lh-26 font-sm text-gray-800 dark-text rajdhani-600 px-lg-5">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid non cupidatat skateboard dolor brunch.</p> */}
            </div>
            <div className="col-12 mt-4 d-flex justify-content-center">
              <ol className="roadmap-wrapper-style2">
                <li>
                  <div className="roadmap-content">
                    <span className="rajdhani-700 ms-n1 lh-4 new-pink1  display1-size ls-5">
                      Q3 2024
                    </span>
                    <h4 className="h5 text-gray-900 dark-text rajdhani-600">
                      Initial Development{" "}
                    </h4>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm mb-0">
                      {" "}
                      Community Building
                    </p>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm mb-0">
                      Token Launch
                    </p>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm">
                      Trading Bot Launch
                    </p>
                  </div>
                </li>
                <li>
                  <div className="roadmap-content">
                    <span className="rajdhani-700 ms-n1 lh-4 new-pink1  display1-size ls-5">
                      Q4 2024{" "}
                    </span>
                    <h4 className="h5 text-gray-900 dark-text rajdhani-600">
                      Acceleration
                    </h4>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm mb-0">
                      {" "}
                      Staking Launch
                    </p>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm mb-0">
                      DEX Liquidity
                    </p>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm">
                      Marketing Campaigns
                    </p>{" "}
                  </div>
                </li>
                <li>
                  <div className="roadmap-content">
                    <span className="rajdhani-700 ms-n1 lh-4 new-pink1  display1-size ls-5">
                      Q1 2025
                    </span>
                    <h4 className="h5 text-gray-900 dark-text rajdhani-600">
                      Leveling Up
                    </h4>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm mb-0">
                      {" "}
                      Exchange Listing
                    </p>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm mb-0">
                      DeFi Integrations
                    </p>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm">
                      Market making
                    </p>{" "}
                  </div>
                </li>
                <li>
                  <div className="roadmap-content">
                    <span className="rajdhani-700 ms-n1 lh-4 new-pink1  display1-size ls-5">
                      Q2 2025
                    </span>
                    <h4 className="h5 text-gray-900 dark-text rajdhani-600">
                      Step-up Further
                    </h4>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm mb-0">
                      {" "}
                      NFT Collection Launch
                    </p>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm mb-0">
                      AI-Crypto Tools
                    </p>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm">
                      Ecosystem Expansion
                    </p>{" "}
                  </div>
                </li>
                <li>
                  <div className="roadmap-content">
                    <span className="rajdhani-700 ms-n1 lh-4 new-pink1  display1-size ls-5">
                      Q3 2025
                    </span>
                    <h4 className="h5 text-gray-900 dark-text rajdhani-600">
                      Acceleration – Phase 2
                    </h4>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm mb-0">
                      {" "}
                      Crypto ChatBots
                    </p>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm mb-0">
                      Subscriptions Plans
                    </p>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm">
                      Global Outreach
                    </p>{" "}
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
