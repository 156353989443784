import React from "react";

export default function LokerBox() {
  return (
    <div>
      <div className="dash-main-content ">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row mt-3">
              <div className="col-lg-4">
                <div className="card-body mt-3 light-bg dark-border border border-light border-2 rounded-10 p-3">
                  <div className="d-flex mb-0 justify-content-between">
                    <h6 className="text-gray-900 rajdhani-700 mb-0 font-md pb-2 mt-2">
                   Slote
                    </h6>
           
                  </div>
                  <div className="d-flex posr">
                    <canvas
                      id="doughnutChart"
                      className="doughnutChart graph p-2"
                    />
                    <h2 className="rajdhani-700 text-gray-900 h1 lh-1 dark-text p-0 position-absolute top-50 start-50 translate-middle ">
                      86%{" "}
                      <span className="font-xs d-block rajdhani-500 text-gray-500">
                        Total Sales
                      </span>
                    </h2>
                  </div>
                  <div className="d-flex gap-3 flex-row justify-content-center mt-2">
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-success mt-1" />
                      Purchase
                    </h6>
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-warning mt-1" />
                      Sales
                    </h6>
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-primary mt-1" />
                      Endorse
                    </h6>
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-cyan mt-1" />
                      Nfts
                    </h6>
                  </div>
                  <div className="d-flex gap-5 flex-row justify-content-center mt-3 mb-2">
                    <div className="d-flex flex-row gap-2">
                      <i className="btn btn-icon btn-md alert-warning border-0 text-warning rounded-pill font-md bi-currency-exchange" />
                      <h6 className="rajdhani-600 text-gray-900 dark-text font-md">
                        456,643{" "}
                        <span className="d-block font-xs text-gray-500">
                          Total Expense
                        </span>
                      </h6>
                    </div>
                    <div className="d-flex flex-row gap-2">
                      <i className="btn btn-icon btn-md alert-secondary border-0 text-secondary rounded-pill font-md bi-bookmark-fill" />
                      <h6 className="rajdhani-600 text-gray-900 dark-text font-md">
                        5.565B{" "}
                        <span className="d-block font-xs text-gray-500">
                          Total Income
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-body mt-3 light-bg dark-border border border-light border-2 rounded-10 p-3">
                  <div className="d-flex mb-0 justify-content-between">
                    <h6 className="text-gray-900 rajdhani-700 mb-0 font-md pb-2 mt-2">
                    cooling period
                    </h6>
    
                  </div>
                  <div className="d-flex posr">
                    <canvas
                      id="doughnutChart"
                      className="doughnutChart graph p-2"
                    />
                    <h2 className="rajdhani-700 text-gray-900 h1 lh-1 dark-text p-0 position-absolute top-50 start-50 translate-middle ">
                      86%{" "}
                      <span className="font-xs d-block rajdhani-500 text-gray-500">
                        Total Sales
                      </span>
                    </h2>
                  </div>
                  <div className="d-flex gap-3 flex-row justify-content-center mt-2">
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-success mt-1" />
                      Purchase
                    </h6>
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-warning mt-1" />
                      Sales
                    </h6>
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-primary mt-1" />
                      Endorse
                    </h6>
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-cyan mt-1" />
                      Nfts
                    </h6>
                  </div>
                  <div className="d-flex gap-5 flex-row justify-content-center mt-3 mb-2">
                    <div className="d-flex flex-row gap-2">
                      <i className="btn btn-icon btn-md alert-warning border-0 text-warning rounded-pill font-md bi-currency-exchange" />
                      <h6 className="rajdhani-600 text-gray-900 dark-text font-md">
                        456,643{" "}
                        <span className="d-block font-xs text-gray-500">
                          Total Expense
                        </span>
                      </h6>
                    </div>
                    <div className="d-flex flex-row gap-2">
                      <i className="btn btn-icon btn-md alert-secondary border-0 text-secondary rounded-pill font-md bi-bookmark-fill" />
                      <h6 className="rajdhani-600 text-gray-900 dark-text font-md">
                        5.565B{" "}
                        <span className="d-block font-xs text-gray-500">
                          Total Income
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-body mt-3 light-bg dark-border border border-light border-2 rounded-10 p-3">
                  <div className="d-flex mb-0 justify-content-between">
                    <h6 className="text-gray-900 rajdhani-700 mb-0 font-md pb-2 mt-2">
Every Month
                    </h6>
                    <select
                      className="form-select form-select-sm border-light rounded-6 w-110 font-xs light-bg dark-border dark-text text-gray-900 rajdhani-600 bg-transparent py-0 h-40"
                      aria-label="select example"
                    >
                      <option value>Week</option>
                      <option value={1}>Month</option>
                      <option value={2}>Day</option>
                      <option value={3}>Year</option>
                    </select>
                  </div>
                  <div className="d-flex posr">
                    <canvas
                      id="doughnutChart"
                      className="doughnutChart graph p-2"
                    />
                    <h2 className="rajdhani-700 text-gray-900 h1 lh-1 dark-text p-0 position-absolute top-50 start-50 translate-middle ">
                      86%{" "}
                      <span className="font-xs d-block rajdhani-500 text-gray-500">
                        Total Sales
                      </span>
                    </h2>
                  </div>
                  <div className="d-flex gap-3 flex-row justify-content-center mt-2">
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-success mt-1" />
                      Purchase
                    </h6>
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-warning mt-1" />
                      Sales
                    </h6>
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-primary mt-1" />
                      Endorse
                    </h6>
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-cyan mt-1" />
                      Nfts
                    </h6>
                  </div>
                  <div className="d-flex gap-5 flex-row justify-content-center mt-3 mb-2">
                    <div className="d-flex flex-row gap-2">
                      <i className="btn btn-icon btn-md alert-warning border-0 text-warning rounded-pill font-md bi-currency-exchange" />
                      <h6 className="rajdhani-600 text-gray-900 dark-text font-md">
                        456,643{" "}
                        <span className="d-block font-xs text-gray-500">
                          Total Expense
                        </span>
                      </h6>
                    </div>
                    <div className="d-flex flex-row gap-2">
                      <i className="btn btn-icon btn-md alert-secondary border-0 text-secondary rounded-pill font-md bi-bookmark-fill" />
                      <h6 className="rajdhani-600 text-gray-900 dark-text font-md">
                        5.565B{" "}
                        <span className="d-block font-xs text-gray-500">
                          Total Income
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              {/* table */}
              <div className="col-lg-12 mt-3 mb-4">
                <div className="card-body mt-3 light-bg dark-border border border-light border-2 rounded-10 p-3">
                  <div className="d-flex mb-3 justify-content-between">
                    <h6 className="text-gray-900 rajdhani-700 font-md pb-2 mt-2">
                     History
                    </h6>
               
                  </div>
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="thead-light bg-gray-100 text-gray-900 dark-text-black rounded-6 ovh light-bg">
                        <tr>
                          <th scope="col" className="py-1" />
                          <th scope="col" className="py-1">
                            <span className="rajdhani-600 lh-24 font-xs m-0">
                              Price
                            </span>
                          </th>
                          <th scope="col" className="py-1">
                            <span className="rajdhani-600 lh-24 font-xs m-0">
                              Status
                            </span>
                          </th>
                          <th scope="col" className="py-1">
                            <span className="rajdhani-600 lh-24 font-xs m-0">
                              Date
                            </span>
                          </th>
                          <th scope="col" className="py-1">
                            <span className="rajdhani-600 lh-24 font-xs m-0">
                              Assets
                            </span>
                          </th>
                          <th scope="col" className="py-1">
                            <span className="rajdhani-600 lh-24 font-xs m-0">
                              Members
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="ps-0">
                            <div className="d-flex flex-row gap-2">
                              <figure className="w-35 mb-0">
                                <img
                                  src="assets/images/mana.svg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <div className="d-flex flex-column">
                                <h6 className="rajdhani-600 text-gray-900 dark-text font-sm lh-16 mb-0">
                                  Bitcoin
                                </h6>
                                <span className="font-xss text-gray-500">
                                  BTC
                                </span>
                              </div>
                            </div>
                          </th>
                          <td>
                            <h6 className="rajdhani-600 mb-0 font-xss">
                              4,881.65 ETH
                            </h6>
                          </td>
                          <td>
                            <span className="text-success mb-0 px-2 py-0 font-xss rajdhani-600 rounded-6 alert-success inter-700 font-xsssss d-inline-block">
                              {" "}
                              Done
                            </span>
                          </td>
                          <td>
                            <h6 className="rajdhani-600 mb-0 font-xss">
                              22 Nov 2022
                            </h6>
                          </td>
                          <td>
                            <h6 className="text-success font-xs rajdhani-600 m-0">
                              49.56 %
                            </h6>
                          </td>
                          <td>
                            <div className="d-flex justify-content-start ms-n3">
                              <figure className="w-30 mb-0 ms-3">
                                <img
                                  src="assets/images/artist-6.jpg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <figure className="w-30 mb-0 ms-n3">
                                <img
                                  src="assets/images/artist-4.jpg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <figure className="w-30 mb-0 ms-n3">
                                <img
                                  src="assets/images/artist-3.jpg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <figure className="w-30 mb-0 ms-n3">
                                <img
                                  src="assets/images/avater-1.png"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="ps-0">
                            <div className="d-flex flex-row gap-2">
                              <figure className="w-35 p-1 mb-0 bg-gray-200 rounded-pill">
                                <img
                                  src="assets/images/eth.svg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <div className="d-flex flex-column">
                                <h6 className="rajdhani-600 text-gray-900 dark-text font-sm lh-16 mb-0">
                                  Ethirium
                                </h6>
                                <span className="font-xss text-gray-500">
                                  ETH
                                </span>
                              </div>
                            </div>
                          </th>
                          <td>
                            <h6 className="rajdhani-600 mb-0 font-xss">
                              544.25 ETH
                            </h6>
                          </td>
                          <td>
                            <span className="text-info mb-0 px-2 py-0 font-xss rajdhani-600 rounded-6 alert-info inter-700 font-xsssss d-inline-block">
                              {" "}
                              Progress
                            </span>
                          </td>
                          <td>
                            <h6 className="rajdhani-600 mb-0 font-xss">
                              12 Dec 2022
                            </h6>
                          </td>
                          <td>
                            <h6 className="text-danger font-xs rajdhani-600 m-0">
                              22.45 %
                            </h6>
                          </td>
                          <td>
                            <div className="d-flex justify-content-start ms-n3">
                              <figure className="w-30 mb-0 ms-3">
                                <img
                                  src="assets/images/artist-4.jpg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <figure className="w-30 mb-0 ms-n3">
                                <img
                                  src="assets/images/artist-5.jpg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <figure className="w-30 mb-0 ms-n3">
                                <img
                                  src="assets/images/avater-2.png"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="ps-0">
                            <div className="d-flex flex-row gap-2">
                              <figure className="w-35 mb-0">
                                <img
                                  src="assets/images/btc.svg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <div className="d-flex flex-column">
                                <h6 className="rajdhani-600 text-gray-900 dark-text font-sm lh-16 mb-0">
                                  Bitcoin
                                </h6>
                                <span className="font-xss text-gray-500">
                                  BTC
                                </span>
                              </div>
                            </div>
                          </th>
                          <td>
                            <h6 className="rajdhani-600 mb-0 font-xss">
                              342334.65 ETH
                            </h6>
                          </td>
                          <td>
                            <span className="text-warning mb-0 px-2 py-0 font-xss rajdhani-600 rounded-6 alert-warning inter-700 font-xsssss d-inline-block">
                              {" "}
                              Review
                            </span>
                          </td>
                          <td>
                            <h6 className="rajdhani-600 mb-0 font-xss">
                              04 Jan 2023
                            </h6>
                          </td>
                          <td>
                            <h6 className="text-success font-xs rajdhani-600 m-0">
                              12.78 %
                            </h6>
                          </td>
                          <td>
                            <div className="d-flex justify-content-start ms-n3">
                              <figure className="w-30 mb-0 ms-3">
                                <img
                                  src="assets/images/artist-2.jpg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <figure className="w-30 mb-0 ms-n3">
                                <img
                                  src="assets/images/artist-3.jpg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <figure className="w-30 mb-0 ms-n3">
                                <img
                                  src="assets/images/avater-2.png"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="ps-0">
                            <div className="d-flex flex-row gap-2">
                              <figure className="w-35 mb-0">
                                <img
                                  src="assets/images/shib.svg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <div className="d-flex flex-column">
                                <h6 className="rajdhani-600 text-gray-900 dark-text font-sm lh-16 mb-0">
                                  Shibinu
                                </h6>
                                <span className="font-xss text-gray-500">
                                  SHIB
                                </span>
                              </div>
                            </div>
                          </th>
                          <td>
                            <h6 className="rajdhani-600 mb-0 font-xss">
                              4,881.65 ETH
                            </h6>
                          </td>
                          <td>
                            <span className="text-success mb-0 px-2 py-0 font-xss rajdhani-600 rounded-6 alert-success inter-700 font-xsssss d-inline-block">
                              {" "}
                              Done
                            </span>
                          </td>
                          <td>
                            <h6 className="rajdhani-600 mb-0 font-xss">
                              23 Jan 2023
                            </h6>
                          </td>
                          <td>
                            <h6 className="text-danger font-xs rajdhani-600 m-0">
                              43.23 %
                            </h6>
                          </td>
                          <td>
                            <div className="d-flex justify-content-start ms-n3">
                              <figure className="w-30 mb-0 ms-3">
                                <img
                                  src="assets/images/avater-4.png"
                                  alt="avater"
                                  className="w-100"
                                />
                              </figure>
                              <figure className="w-30 mb-0 ms-n3">
                                <img
                                  src="assets/images/avater-3.png"
                                  alt="avater"
                                  className="w-100"
                                />
                              </figure>
                              <figure className="w-30 mb-0 ms-n3">
                                <img
                                  src="assets/images/avater-2.png"
                                  alt="avater"
                                  className="w-100"
                                />
                              </figure>
                              <figure className="w-30 mb-0 ms-n3">
                                <img
                                  src="assets/images/n-2.png"
                                  alt="avater"
                                  className="w-100"
                                />
                              </figure>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="ps-0">
                            <div className="d-flex flex-row gap-2">
                              <figure className="w-35 mb-0">
                                <img
                                  src="assets/images/mana.svg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <div className="d-flex flex-column">
                                <h6 className="rajdhani-600 text-gray-900 dark-text font-sm lh-16 mb-0">
                                  Bitcoin
                                </h6>
                                <span className="font-xss text-gray-500">
                                  BTC
                                </span>
                              </div>
                            </div>
                          </th>
                          <td>
                            <h6 className="rajdhani-600 mb-0 font-xss">
                              4,881.65 ETH
                            </h6>
                          </td>
                          <td>
                            <span className="text-success mb-0 px-2 py-0 font-xss rajdhani-600 rounded-6 alert-success inter-700 font-xsssss d-inline-block">
                              {" "}
                              Done
                            </span>
                          </td>
                          <td>
                            <h6 className="rajdhani-600 mb-0 font-xss">
                              22 Nov 2022
                            </h6>
                          </td>
                          <td>
                            <h6 className="text-success font-xs rajdhani-600 m-0">
                              49.56 %
                            </h6>
                          </td>
                          <td>
                            <div className="d-flex justify-content-start ms-n3">
                              <figure className="w-30 mb-0 ms-3">
                                <img
                                  src="assets/images/artist-6.jpg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <figure className="w-30 mb-0 ms-n3">
                                <img
                                  src="assets/images/artist-4.jpg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <figure className="w-30 mb-0 ms-n3">
                                <img
                                  src="assets/images/artist-3.jpg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <figure className="w-30 mb-0 ms-n3">
                                <img
                                  src="assets/images/avater-1.png"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="ps-0">
                            <div className="d-flex flex-row gap-2">
                              <figure className="w-35 p-1 mb-0 bg-gray-200 rounded-pill">
                                <img
                                  src="assets/images/eth.svg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <div className="d-flex flex-column">
                                <h6 className="rajdhani-600 text-gray-900 dark-text font-sm lh-16 mb-0">
                                  Ethirium
                                </h6>
                                <span className="font-xss text-gray-500">
                                  ETH
                                </span>
                              </div>
                            </div>
                          </th>
                          <td>
                            <h6 className="rajdhani-600 mb-0 font-xss">
                              544.25 ETH
                            </h6>
                          </td>
                          <td>
                            <span className="text-info mb-0 px-2 py-0 font-xss rajdhani-600 rounded-6 alert-info inter-700 font-xsssss d-inline-block">
                              {" "}
                              Progress
                            </span>
                          </td>
                          <td>
                            <h6 className="rajdhani-600 mb-0 font-xss">
                              12 Dec 2022
                            </h6>
                          </td>
                          <td>
                            <h6 className="text-danger font-xs rajdhani-600 m-0">
                              22.45 %
                            </h6>
                          </td>
                          <td>
                            <div className="d-flex justify-content-start ms-n3">
                              <figure className="w-30 mb-0 ms-3">
                                <img
                                  src="assets/images/artist-4.jpg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <figure className="w-30 mb-0 ms-n3">
                                <img
                                  src="assets/images/artist-5.jpg"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                              <figure className="w-30 mb-0 ms-n3">
                                <img
                                  src="assets/images/avater-2.png"
                                  alt="avater"
                                  className="w-100 rounded-pill"
                                />
                              </figure>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
