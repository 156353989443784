import React from "react";
import Marquee from "react-fast-marquee";

export default function Animation() {
  return (
    <div className="">
      <div className="textscroll-wrapper py-3 bg-warning ">
      <Marquee>
          <div className="owl-carousel owl-theme h-35 ovh text-scroll d-flex ">
            <div className="item d-flex gap-2">
              <h1 className="text-gray-900 h4 lh-32 rajdhani-600 m-0 text-uppercase ls-4 text-white mx-2">
                Trade, Explore & Connect to Decentralized Finance (DeFi){" "}
              </h1>
              <img
                src="assets/images/icon-8.png"
                className="w-20 mt-1 h-20"
                alt="icon"
              />
            </div>
            <div className="item d-flex gap-2">
              <h1 className="text-gray-900 h4 lh-32 rajdhani-600 m-0 text-uppercase ls-4 text-white mx-2">
                Trade, Explore & Connect to Decentralized Finance (DeFi){" "}
              </h1>
              <img
                src="assets/images/icon-8.png"
                className="w-20 mt-1 h-20"
                alt="icon"
              />
            </div>
            <div className="item d-flex gap-2">
              <h1 className="text-gray-900 h4 lh-32 rajdhani-600 m-0 text-uppercase ls-4 text-white mx-2">
                Trade, Explore & Connect to Decentralized Finance (DeFi){" "}
              </h1>
              <img
                src="assets/images/icon-8.png"
                className="w-20 mt-1 h-20"
                alt="icon"
              />
            </div>
            <div className="item d-flex gap-2">
              <h1 className="text-gray-900 h4 lh-32 rajdhani-600 m-0 text-uppercase ls-4 text-white mx-2">
                Trade, Explore & Connect to Decentralized Finance (DeFi){" "}
              </h1>
              <img
                src="assets/images/icon-8.png"
                className="w-20 mt-1 h-20"
                alt="icon"
              />
            </div>
            <div className="item d-flex gap-2">
              <h1 className="text-gray-900 h4 lh-32 rajdhani-600 m-0 text-uppercase ls-4 text-white mx-2">
                Trade, Explore & Connect to Decentralized Finance (DeFi){" "}
              </h1>
              <img
                src="assets/images/icon-8.png"
                className="w-20 mt-1 h-20"
                alt="icon"
              />
            </div>
          </div>
        </Marquee>
      </div>

      {/* <div className="textscroll-wrapper py-3 bg-warning z-index-5 rotate-3-deg mt-5 ">
      
      </div> */}
    </div>
  );
}
