import React from "react";

export default function Hero() {
  return (
    <div>
      <div
        className="banner-wrapper pt-125 pb-lg-5  bg-image-cover bg-no-repeat bg-image-bottom-center "
        style={{ backgroundImage: "url(assets/images/hero/hero-section-gradient-file.png)" }}
      >
        <div
          className="banner-bottom-image h-100 mb-5 bg-image-size80 bg-no-repeat bg-image-bottom-center pb-100"
          style={{ backgroundImage: "url(assets/images/bg-24.png)" }}
        >
          <div className="container pb-md-5">
            <div className="row justify-content-center posr">
              <div className="col-lg-5 col-md-6 posa mt-5 d-none d-md-block">
                <img
                  src="assets/images/hero/hero_section_image.png"
                  alt="banner"
                  className="img-fluid mt-5  ani4"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h2 className="display7-size lh-1 text-gray-900 dark-text text-uppercase rajdhani-700">
                  Hub For DeFi Yield
                </h2>
              </div>
              <div className="col-lg-3 text-md-end mt-lg-3 pt-lg-5">
                <h2 className="display7-size lh-1 text-gray-900 dark-text text-uppercase rajdhani-700">
                 
                </h2>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-4">
                <h3 className="rajdhani-600 text-gray-900 dark-text display1-size lh-1 pe-lg-5">
                  Unleash Your DeFi Journey
                </h3>
                <div className="d-flex flex-row flex-md-column flex-lg-column flex-xl-row gap-3 mt-lg-4 pt-3">
                  <a
                    href="#"
                    className="btn btn-lg dark-white text-white light-text rajdhani-700 rounded-6 bg-gray-900 border-dark border-2 font-sm text-uppercase"
                  >
                    Join
                  </a>
                  <a
                    href="#"
                    className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 font-sm text-uppercase"
                  >
                    Learn
                  </a>
                </div>
              </div>
              <div className="col-md-3 mt-lg-5 md-mt-4">
                <div className="d-flex flex-column">
                  <h2 className="display1-size ls-1 lh-1 text-gray-900 dark-text rajdhani-400">
                    $150k+ <span className="font-lg"> </span>
                  </h2>
                  <p className="font-sm rajdhani-600 lh-24 text-gray-900 dark-text">
                    Total funds in Onmax ecosystem
                  </p>
                </div>
                <div className="d-flex flex-column mt-3">
                  <h2 className="display1-size ls-1 lh-1 text-gray-900 dark-text rajdhani-400">
                    3x
                    {/* <i className="bi-star-fill font-sm text-warning" /> */}
                  </h2>
                  <p className="font-sm rajdhani-600 lh-24 text-gray-900 dark-text">
                    Annual average return for users
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
