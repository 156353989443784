import React from "react";

export default function Misson() {
  return (
    <div>
      <div className="nft-wrapper py-5">
        <div className="container pb-lg-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center d-flex justify-content-center gap-2 pb-lg-5">
              <h2 className="text-gray-900 rajdhani-600 h1 mb-0 dark-text">
                We strive to onboard millions users!
              </h2>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6 col-md-12 mt-2">
              <div className="nft-div border dark-bg3 rounded-10 dark-border p-2 p-3 mt-4">
                <div className="d-flex gap-2 p-1 align-items-center ">
                  <figure className="avater mb-0">
                    <img
                      src="assets/images/about/vision.svg"
                      alt="user"
                      width={60}
                      height={60}
                      className=" img-fuild"
                    />
                  </figure>
                  <h5 className="rajdhani-600 text-gray-900 dark-text h5">
                    Vision
                    {/* <i className="bi-patch-check-fill font-sm text-success mt-1" /> */}
                  </h5>
                </div>

                <p className="rajdhani-600 dark-text h6 lh-28 text-gray-700 mb-md-4 pb-2  mt-2">
                  We envision a future where anyone can participate in the
                  financial markets with confidence and ease. By leveraging the
                  power of blockchain technology and decentralized finance, we
                  aim to create a platform that democratizes trading, empowers
                  users, and drives innovation in the crypto space.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-2">
              <div className="nft-div border dark-bg3 rounded-10 dark-border p-2 p-3 mt-4">
                <div className="d-flex gap-2 p-1 align-items-center ">
                  <figure className="avater mb-0">
                    <img
                      src="assets/images/about/mission.svg"
                      alt="user"
                      width={60}
                      height={60}
                      className=" img-fuild"
                    />
                  </figure>
                  <h5 className="rajdhani-600 text-gray-900 dark-text h5">
                    Mission
                    {/* <i className="bi-patch-check-fill font-sm text-success mt-1" /> */}
                  </h5>
                </div>

                <p className="rajdhani-600 dark-text h6 lh-28 text-gray-700  mt-2">
                  Our mission is to empower individuals in the decentralized
                  finance ecosystem by providing innovative, user-friendly
                  trading solutions. We strive to simplify the trading
                  experience, promote financial literacy, and foster a vibrant
                  community where every user can thrive, maximizing their
                  potential in the dynamic world of cryptocurrency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
