import React from "react";

export default function AboutBox() {
  return (
    <div>
      <div class="feature-wrapper pt-50 pb-50">
        <div class="container-large">
          <div class="row justify-content-center mb-75">
            <div class="col-md-8 text-center">
              <h2 class="h1 lh-2 mb-0 text-gray-900 dark-text rajdhani-600">
                Our Offerings
              </h2>
              {/* <p class="px-md-5 mt-2 text-gray-800 dark-text rajdhani-600 font-sm">The Creature World team created the collection by developing a unique algorithm that combined their recognizable art style with specific color.</p> */}
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-4 md-mt-3">
              <div class="icon-div d-flex flex-column ps-7 posr">
                <div class="text-white btn-xl rounded-pill text-center btn-round btn-icon new-pink-bg m-0 posa top-0 start-0">
<img src="assets/images/about/automated_trading_bots.svg" width={50} height={40} alt="" />
                </div>
                <h4 class="h6 text-uppercase text-gray-900 dark-text rajdhani-600 mb-1">
                  Automated Trading Bots{" "}
                </h4>
                <p class="rajdhani-500 dark-text font-sm mb-0 text-gray-900">
                  Our intelligent trading bots operate 24/7, executing trades
                  based on real-time market analysis. Users can customize
                  strategies to fit their individual risk profiles and trading
                  goals..
                </p>
              </div>
            </div>
            <div class="col-md-4 md-mt-3">
              <div class="icon-div d-flex flex-column ps-7 posr">
              <div class="text-white btn-xl rounded-pill text-center btn-round btn-icon new-pink-bg m-0 posa top-0 start-0">
<img src="assets/images/about/yield_farming_&_staking.svg" width={50} height={40} alt="" />
                </div>
                <h4 class="h6 text-uppercase text-gray-900 dark-text rajdhani-600 mb-1">
                  Yield Farming & Staking
                </h4>
                <p class="rajdhani-500 dark-text font-sm mb-0 text-gray-900">
                  Onmax provides users with opportunities to earn passive income
                  through yield farming and staking options, allowing them to
                  maximize their returns while contributing to the ecosystem.
                </p>
              </div>
            </div>
            <div class="col-md-4 md-mt-3">
              <div class="icon-div d-flex flex-column ps-7 posr">
              <div class="text-white btn-xl rounded-pill text-center btn-round btn-icon new-pink-bg m-0 posa top-0 start-0">
<img src="assets/images/about/nfts_collectibles.svg" width={50} height={40} alt="" />
                </div>
                <h4 class="h6 text-uppercase text-gray-900 dark-text rajdhani-600 mb-1">
                  NFTs Collectibles
                </h4>
                <p class="rajdhani-500 dark-text font-sm mb-0 text-gray-900">
                  We integrate unique NFTs with our trading bots, adding an
                  extra layer of personalization and functionality, enabling
                  users to enhance their trading strategies based on their NFT
                  holdings.
                </p>
              </div>
            </div>
            <div class="col-md-4 mt-lg-5 md-mt-3">
              <div class="icon-div d-flex flex-column ps-7 posr">
              <div class="text-white btn-xl rounded-pill text-center btn-round btn-icon new-pink-bg m-0 posa top-0 start-0">
<img src="assets/images/about/educational_resources.svg" width={50} height={40} alt="" />
                </div>
                <h4 class="h6 text-uppercase text-gray-900 dark-text rajdhani-600 mb-1">
                  Educational Resources
                </h4>
                <p class="rajdhani-500 dark-text font-sm mb-0 text-gray-900">
                  We believe in the power of knowledge. Our platform offers a
                  wealth of educational materials, webinars, and community
                  discussions to help users understand the intricacies of
                  trading and DeFi.
                </p>
              </div>
            </div>
            <div class="col-md-4 mt-lg-5 md-mt-3">
              <div class="icon-div d-flex flex-column ps-7 posr">
              <div class="text-white btn-xl rounded-pill text-center btn-round btn-icon new-pink-bg m-0 posa top-0 start-0">
<img src="assets/images/about/community_access.svg" width={50} height={40} alt="" />
                </div>
                <h4 class="h6 text-uppercase text-gray-900 dark-text rajdhani-600 mb-1">
                  Community Access
                </h4>
                <p class="rajdhani-500 dark-text font-sm mb-0 text-gray-900">
                  We value collaboration and support and our vibrant community
                  is a space for traders to share insights, strategies, and
                  experiences, fostering an environment of growth and mutual
                  success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
