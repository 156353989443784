import React from "react";
import Header from "../Coman/Header";
import Hero from "./Hero";
import Rodemap from "./Rodemap";
import Collection from "./Collection";
import Animation from "./Animation";
import Expertize from "./Expertize";
import Inspried from "./Inspried";
import Awesome from "./Awesome";
import Works from "./Works";
import Howworks from "./Howworks";
import Discover from "./Discover";
import Footer from "../Coman/Footer";
import Projects from "./Projects";
import Box from "./Box";
import Fooertop from "./Fooertop";

export default function Index() {
  return (
    <div>
      <Header />
      <Hero />
      <Animation />
      <Expertize />
      <Inspried />
      <Works />
      <Awesome />
      {/* <Box/> */}
      {/* <Howworks/> */}
      <Discover />
      <Projects />
      <Rodemap />
      <Collection />
      <Fooertop />
      <Footer />
    </div>
  );
}
