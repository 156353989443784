import React from "react";

export default function StakingBox() {
  return (
    <>
      <div className="main-wrapper bg-image-cover bg-image-center">
        <div className="container mb-3">
          <div className="row mt-4">
            <div className="d-flex align-items-center justify-content-center">
              <div className="col-lg-8">
                <div className="shadow-lg p-lg-4 p-3 text-center rounded-10 bg-white w-100">
                  <div className="d-flex text-start flex-column mt-5">
                    <h1 className="rajdhani-700 mt-0 mb-0 text-gray-800 ls-0 lh-1 h2">
                      Staking
                    </h1>
                    {/* <h6 className="text-gray-500 lh-20 mt-2 font-xs">
                      Enter your information to get started.
                    </h6> */}
                    <form className="form-style-floating style2 text-center mt-4">
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className="form-control rounded-10 font-xsss text-gray-800 rajdhani-600"
                          id="floatingEmail"
                          placeholder="Enter Referral Address"
                        />
                        <label htmlFor="floatingEmail">Referral Address</label>
                      </div>
                    </form>{" "}
                    <h5 className="text-center new-pink1 rajdhani-600 py-2">
                      Min: $50 Max $25000
                    </h5>
                    <form className="form-style-floating style2 text-center mt-2">
                      <div className="form-floating mb-3">
                        <input
                          type="number"
                          className="form-control rounded-10 font-xsss text-gray-800 rajdhani-600"
                          id="floatingPass"
                          placeholder="Amount"
                        />
                        <label htmlFor="floatingPass">Amount</label>
                      </div>
                    </form>
                    <div>
                      <div className="card w-100 p-4 mt-3 bg-white  rounded-10 flex-column d-flex justify-content-center  ">
                        <h6 className="text-gray-900 rajdhani-600 lh-1 mb-0 mt-1 text-center font-md dark-text d-flex mb-2">
                        Stake  <span className="ms-auto  rajdhani-700 dark-text">5%</span> 
                        </h6>
                        <h6 className="text-gray-900 rajdhani-600 lh-1 mb-0 mt-1 text-center font-md dark-text d-flex  b-2">
                        Token Rate : 
                        <span className="ms-auto  rajdhani-700 dark-text">$0</span>
                        </h6>
                        <h6 className="text-gray-900 rajdhani-600 lh-1 mb-0 mt-1 text-center font-md dark-text mb-2 d-flex">
                        Rate :<span className="ms-auto  rajdhani-700 dark-text">$540 / 1 BNB</span>
                        </h6>
                        <h6 className="text-gray-900 rajdhani-600 lh-1 mb-0 mt-1 text-center font-md dark-text  mb-2 d-flex">
                        Fee :  <span className="ms-auto  rajdhani-700 dark-text"> 0.00 BNB (0 %)%</span>
                        </h6>
                      </div>
                    </div>
                    <form className="form-style-floating style2 text-center mt-2">
                      <button
                        type="button"
                        className="btn btn-lg bg-current text-gray-900 rajdhani-600 text-uppercase btn-block mb-0 rounded-10"
                      >
                      Stake
                      </button>
                    </form>
                  </div>
                  {/* <div className="mt-2 pt-2 text-center w-100">
                    <h6 className="mb-2 mt-2 rajdhani-600 font-xss mx-auto d-block text-center">
                      or sign in with
                    </h6>
                    <div className="justify-content-center d-flex gap-3 ">
                      <a
                        href="#"
                        className="btn btn-icon btn-lg bg-light text-danger rounded-pill"
                      >
                        <i className="bi-google m-0" />
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon btn-lg bg-light text-primary rounded-pill"
                      >
                        <i className="bi-facebook m-0" />
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon btn-lg bg-light text-dark rounded-pill"
                      >
                        <i className="bi-apple m-0" />
                      </a>
                    </div>
                    <h6 className="btn btn-lg border-0 py-0 text-gray-600 btn-block mt-2 mb-0 rounded-6 rajdhani-600 font-xss">
                      Already have a account?{" "}
                      <a
                        href="register-one.html"
                        className="text-gray-900 ms-1 inter-600"
                      >
                        {" "}
                        Register{" "}
                      </a>{" "}
                    </h6>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
