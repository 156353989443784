import React from 'react'
import Header from '../Coman/Header'
import FarmingHero from './FarmingHero'
import FarmingMain from './FarmingMain'
import Create from './Create'
import FCreate from './FCreate'
import Expertize from './Expertize'
import Footer from '../Coman/Footer'
import FarmingYield from './FarmingYield'

export default function Farming() {
  return (
    <div>
      <Header/>
      <FarmingHero/>
      {/* <FarmingMain/> */}
      <FCreate/>
      <Create/>
      <FarmingYield/>
      <Expertize/>
      <Footer/>
    </div>
  )
}
