import React from 'react'
import Header from '../Coman/Header'
import NftsHero from './NftsHero'
import NFtBox from './NFtBox'
import NftIm from './NftIm'
import NftMap from './NftMap'
import OnmaxNft from './OnmaxNft'
import Footer from '../Coman/Footer'

function NFTs() {
  return (
    <div>
        <Header/>
        <NftsHero/>
        <NFtBox/>
        <NftIm/>
        <NftMap/>
        <OnmaxNft/>
        <Footer/>
    </div>
  )
}

export default NFTs