import React from 'react'

export default function StakingHero() {
  return (
    <div>
              <div
        className="banner-wrapper pb-4 pt--lg-100 dark-bg posr"
        // style={{
        //   background:
        //     "linear-gradient(rgb(149 134 217), rgba(204, 123, 204, 0.22))",
        // }}
      >
        <div className="banner-pattern">
          <img
            src="assets/images/pattern-2.png"
            alt="pattern"
            className="ani4 pattern1"
          />
          <img
            src="assets/images/pattern-1.png"
            alt="pattern"
            className="ani2 pattern2"
          />
          <img
            src="assets/images/pattern-1.png"
            alt="pattern"
            className="ani4 pattern3"
          />
          <img
            src="assets/images/pattern-2.png"
            alt="pattern"
            className="ani2 pattern4"
          />
          <img
            src="assets/images/pattern-2.png"
            alt="pattern"
            className="ani4 pattern5"
          />
          <img
            src="assets/images/pattern-3.png"
            alt="pattern"
            className="ani3 pattern6"
          />
        </div>
        <div className="container py-xl-5">
          <div className="row justify-content-between">
            <div className="col-lg-6 col-md-7 mt-md-4 md-mb-3">
              <h1 className="display5-size lh-1 text-gray-900 dark-text rajdhani-700">
              Earn While You Hold
                {/* <span
                  className="bg-no-repeat bg-image-center bg-image-contain p-2 pt-3"
                  style={{
                    backgroundImage: "url(assets/images/text-bg-2.png)",
                  }}
                >
                  farm{" "}OM
                </span>{" "} */}
                {/* <span
                  className="bg-no-repeat bg-image-center bg-image-contain p-2 pt-3 ms-2"
                  style={{
                    backgroundImage: "url(assets/images/text-bg-3.png)",
                  }}
                >
                  {" "}
                  
                </span> */}
              </h1>
              <div className="d-flex flex-row gap-3">
               
                <p className="rajdhani-600 dark-text h6 lh-28 text-gray-900 w-md-75 mt-2 pe-md-5">
                Onmax staking program allows you to earn passive income simply by holding your cryptocurrencies in a secure and user-friendly environment. With this strategy, you can enjoy consistent returns while supporting the network's security and operations.

                </p>
              </div>
              <div className="d-flex flex-row gap-3 mt-4">
                <a
                  href="#"
                  className="btn btn-lg text-white rajdhani-600 rounded-6 bg-gray-900 border-dark border-2 px-lg-5 font-sm text-uppercase"
                >
            Stake Now
                </a>
                {/* <a
                  href="#"
                  className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 px-lg-5 font-sm text-uppercase"
                >
                  Join as creater
                </a> */}
              </div>
            </div>
            <div className="col-lg-5 col-md-5 ps-md-4 posr">
            <video
                playsinline="playsinline"
                muted="muted"
                preload="yes"
                autoplay="autoplay"
                loop="loop"
                id="vjs_video_739_html5_api"
                class="video-js w-100 h-100"
                data-setup='{"autoplay":"any"}'
              >
                <source src="assets/images/staking/staking-hero.mp4" type="video/mp4" />
              </video>
              {/* <div className="position-absolute top-50 start-0 translate-middle">
                <img
                  src="assets/images/round-text-2.png"
                  alt="icon"
                  className="w-110 rotate-circle"
                />
              </div> */}
            </div>
          </div>
        </div>
    
      </div>
    </div>
  )
}
