import { Line } from "rc-progress";
import React from "react";
import CountdownTimer from "../../Coman/CountdownTimer";

export default function DashboardHero() {
  return (
    <div>
      <div className="dash-main-content">
        <div className="middle-sidebar-bottom">
          {/* middle wrap */}
          <div className="middle-sidebar-left">
            <div
              className="card p-0 border-0 rounded-15 px-4 pt-4 bg-current bg-size-cover bg-no-repeat bg-image-center"
              style={{ backgroundImage: "url(assets/images/banner-bg-7.png)" }}
            >
              <div className="row">
                <div className="col-md-6 py-md-5 ps-md-5">
                  <h5 className="text-gray-900 h1 rajdhani-500 mb-1">
                    Discover <b className="rajdhani-700">create</b> and sell
                    your own <b>NFTS. </b>{" "}
                  </h5>
                  <p className="font-sm lh-28 rajdhani-600 text-gray-900 mb-1">
                    Eros donec ac odio tempor orci dapibus ultrices in iaculis.
                    Orci phasellus egestas tellus rutrum tellus..
                  </p>
                  <a
                    className="btn btn-lg border-0 rounded-0 mt-1 text-gray-900 bg-white rounded-pill font-xs rajdhani-700 px-4 me-1 text-uppercase"
                    href="/stake"
                  >
                    Stake
                  </a>
                  <a
                    className="btn btn-lg border-0 rounded-0 mt-1 text-darkyellow bg-dark rounded-pill font-xs rajdhani-600 px-4 text-uppercase"
                    href="#"
                  >
                    Artwork
                  </a>
                </div>
                <div className="col-md-1" />
                <div className="col-md-4">
                  <img
                    src="assets/images/nft-1.png"
                    alt="nft"
                    className="w-300 mt-3"
                  />
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-4">
                <div className="card-body light-bg dark-border border border-light border-2 rounded-10">
                  <div className="row">
                    <div className="col-6 pe-0">
                      <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm">
                        Sales
                      </h6>
                      <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                        78,673
                        <span className="h6 text-gray-600 inter-600"> ETH</span>
                      </h2>
                      <span className="text-success px-1 py-0 font-xss rajdhani-700 rounded-6 alert-success inter-700 font-xsssss d-inline-block">
                        <i className="bi-arrow-up-right lh-22" /> 50%
                      </span>
                    </div>
                    <div className="col-6">
                      <div className="chart-container mt-4 w-100">
                        <canvas className="graph" id="sidebarone" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-body light-bg dark-border border border-light border-2 rounded-10">
                  <div className="row">
                    <div className="col-6 pe-0">
                      <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm">
                        Volumn
                      </h6>
                      <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                        2.3B
                        <span className="h6 text-gray-600 inter-600"> ETH</span>
                      </h2>
                      <span className="text-warning px-1 py-0 font-xss rajdhani-700 rounded-6 alert-warning inter-700 font-xsssss d-inline-block">
                        <i className="bi-arrow-down-right lh-22" /> 23%
                      </span>
                    </div>
                    <div className="col-6">
                      <div className="chart-container mt-4 w-100">
                        <canvas className="graph" id="sidebartwo" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-body light-bg dark-border border border-light border-2 rounded-10">
                  <div className="row">
                    <div className="col-6 pe-0">
                      <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm">
                        Balance
                      </h6>
                      <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                        5.5M
                        <span className="h6 text-gray-600 inter-600"> ETH</span>
                      </h2>
                      <span className="text-success px-1 py-0 font-xss rajdhani-700 rounded-6 alert-success inter-700 font-xsssss d-inline-block">
                        <i className="bi-arrow-up-right lh-22" /> 22%
                      </span>
                    </div>
                    <div className="col-6">
                      <div className="chart-container mt-4 w-100">
                        <canvas className="graph" id="sidebarthree" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div className="card-body mt-3 light-bg dark-border border border-light border-2 rounded-10 p-3">
                  <div className="d-flex mb-4 justify-content-between">
                    <h6 className="text-gray-900 rajdhani-700 m-02 font-md pb-2 mt-2">
                      Price Distribution
                    </h6>
                  </div>
                  <div className="row align-items-center">
                  <div className="col-lg-8">
                    <Line
                      percent={10}
                      strokeWidth={2}
                      strokeColor="#eb6502"
                      trailWidth={2}
                      trailColor="#005c9f"
                    />
                  </div>
                  <div className="col-lg-4">
                    {" "}
                    <CountdownTimer targetDate={"2024-10-15T15:00:00"} />
                  </div>
                  </div>
                 
                </div>
              </div>
              <div className="col-lg-7 mt-3 ">
                <div className="card-body mt-3 light-bg dark-border border border-light border-2 rounded-10 p-3">
                  <div className="d-flex mb-4 justify-content-between">
                    <h6 className="text-gray-900 rajdhani-700 mt-1 font-md pb-2">
                      Analytics
                    </h6>
                    <div className="d-flex gap-3 flex-row justify-content-center ms-auto mt-2">
                      <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                        <span className="d-inline-block h-10 w-10 rounded-10 bg-success mt-1" />
                        Purchase
                      </h6>
                      <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                        <span className="d-inline-block h-10 w-10 rounded-10 bg-warning mt-1" />
                        Sales
                      </h6>
                      <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                        <span className="d-inline-block h-10 w-10 rounded-10 bg-primary mt-1" />
                        Endorse
                      </h6>
                      <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                        <span className="d-inline-block h-10 w-10 rounded-10 bg-cyan mt-1" />
                        Nfts
                      </h6>
                    </div>
                  </div>
                  <canvas id="stackedChart" className="stackedChart graph" />
                </div>
                <div className="card-body mt-4 light-bg dark-border border border-light border-2 rounded-10 p-0">
                  <div className="row">
                    <div className="col-lg-6 pe-0">
                      <div className="d-flex p-4 border-light border-2 border-end">
                        <div className="col-9 pe-0">
                          <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm">
                            New Users
                          </h6>
                          <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                            4.73M
                          </h2>
                          <div className="d-flex flex-row gap-2">
                            <h6 className="text-success mb-0 px-2 py-0 font-xss rajdhani-700 rounded-6 alert-success inter-700 font-xsssss d-inline-block">
                              <i className="bi-arrow-up-right lh-22" /> 50.77%
                            </h6>
                            <h6 className="rajdhani-600 mb-0 text-gray-500 font-xs lh-22">
                              432 Purchase
                            </h6>
                          </div>
                        </div>
                        <div className="col-3 ps-0">
                          <i className="btn btn-icon btn-md ms-auto alert-primary border-0 text-primary rounded-pill font-lg bi-award-fill" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 ps-0">
                      <div className="d-flex p-4">
                        <div className="col-9 pe-0">
                          <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm">
                            Transaction
                          </h6>
                          <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                            22.2B
                          </h2>
                          <div className="d-flex flex-row gap-2">
                            <h6 className="text-warning mb-0 px-2 py-0 font-xss rajdhani-700 rounded-6 alert-warning inter-700 font-xsssss d-inline-block">
                              <i className="bi-arrow-down-right lh-22" /> 22.77%
                            </h6>
                            <h6 className="rajdhani-600 mb-0 text-gray-500 font-xs lh-22">
                              232 Sales
                            </h6>
                          </div>
                        </div>
                        <div className="col-3 ps-0">
                          <i className="btn btn-icon btn-md ms-auto alert-danger border-0 text-danger rounded-pill font-lg bi-bag-fill" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 mt-3 ">
                <div className="card-body mt-3 light-bg dark-border border border-light border-2 rounded-10 p-3">
                  <div className="d-flex mb-0 justify-content-between">
                    <h6 className="text-gray-900 rajdhani-700 mb-0 font-md pb-2 mt-2">
                      Earning report
                    </h6>
                    <select
                      className="form-select form-select-sm border-light rounded-6 w-110 font-xs light-bg dark-border dark-text text-gray-900 rajdhani-600 bg-transparent py-0 h-40"
                      aria-label="select example"
                    >
                      <option value>Week</option>
                      <option value={1}>Month</option>
                      <option value={2}>Day</option>
                      <option value={3}>Year</option>
                    </select>
                  </div>
                  <div className="d-flex posr">
                    <canvas
                      id="doughnutChart"
                      className="doughnutChart graph p-2"
                    />
                    <h2 className="rajdhani-700 text-gray-900 h1 lh-1 dark-text p-0 position-absolute top-50 start-50 translate-middle ">
                      86%{" "}
                      <span className="font-xs d-block rajdhani-500 text-gray-500">
                        Total Sales
                      </span>
                    </h2>
                  </div>
                  <div className="d-flex gap-3 flex-row justify-content-center mt-2">
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-success mt-1" />
                      Purchase
                    </h6>
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-warning mt-1" />
                      Sales
                    </h6>
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-primary mt-1" />
                      Endorse
                    </h6>
                    <h6 className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text">
                      <span className="d-inline-block h-10 w-10 rounded-10 bg-cyan mt-1" />
                      Nfts
                    </h6>
                  </div>
                  <div className="d-flex gap-5 flex-row justify-content-center mt-3 mb-2">
                    <div className="d-flex flex-row gap-2">
                      <i className="btn btn-icon btn-md alert-warning border-0 text-warning rounded-pill font-md bi-currency-exchange" />
                      <h6 className="rajdhani-600 text-gray-900 dark-text font-md">
                        456,643{" "}
                        <span className="d-block font-xs text-gray-500">
                          Total Expense
                        </span>
                      </h6>
                    </div>
                    <div className="d-flex flex-row gap-2">
                      <i className="btn btn-icon btn-md alert-secondary border-0 text-secondary rounded-pill font-md bi-bookmark-fill" />
                      <h6 className="rajdhani-600 text-gray-900 dark-text font-md">
                        5.565B{" "}
                        <span className="d-block font-xs text-gray-500">
                          Total Income
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-3 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end border">
                  <div className="col-9 pe-0">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm">
                      New Users
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      4.73M
                    </h2>
                    <div className="d-flex flex-row gap-2">
                      <h6 className="text-success mb-0 px-2 py-0 font-xss rajdhani-700 rounded-6 alert-success inter-700 font-xsssss d-inline-block">
                        <i className="bi-arrow-up-right lh-22" /> 50.77%
                      </h6>
                      <h6 className="rajdhani-600 mb-0 text-gray-500 font-xs lh-22">
                        432 Purchase
                      </h6>
                    </div>
                  </div>
                  <div className="col-3 ps-0">
                    <i className="btn btn-icon btn-md ms-auto alert-primary border-0 text-primary rounded-pill font-lg bi-award-fill" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end border">
                  <div className="col-9 pe-0">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm">
                      New Users
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      4.73M
                    </h2>
                    <div className="d-flex flex-row gap-2">
                      <h6 className="text-success mb-0 px-2 py-0 font-xss rajdhani-700 rounded-6 alert-success inter-700 font-xsssss d-inline-block">
                        <i className="bi-arrow-up-right lh-22" /> 50.77%
                      </h6>
                      <h6 className="rajdhani-600 mb-0 text-gray-500 font-xs lh-22">
                        432 Purchase
                      </h6>
                    </div>
                  </div>
                  <div className="col-3 ps-0">
                    <i className="btn btn-icon btn-md ms-auto alert-primary border-0 text-primary rounded-pill font-lg bi-award-fill" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end border">
                  <div className="col-9 pe-0">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm">
                      New Users
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      4.73M
                    </h2>
                    <div className="d-flex flex-row gap-2">
                      <h6 className="text-success mb-0 px-2 py-0 font-xss rajdhani-700 rounded-6 alert-success inter-700 font-xsssss d-inline-block">
                        <i className="bi-arrow-up-right lh-22" /> 50.77%
                      </h6>
                      <h6 className="rajdhani-600 mb-0 text-gray-500 font-xs lh-22">
                        432 Purchase
                      </h6>
                    </div>
                  </div>
                  <div className="col-3 ps-0">
                    <i className="btn btn-icon btn-md ms-auto alert-primary border-0 text-primary rounded-pill font-lg bi-award-fill" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end border">
                  <div className="col-9 pe-0">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm">
                      New Users
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      4.73M
                    </h2>
                    <div className="d-flex flex-row gap-2">
                      <h6 className="text-success mb-0 px-2 py-0 font-xss rajdhani-700 rounded-6 alert-success inter-700 font-xsssss d-inline-block">
                        <i className="bi-arrow-up-right lh-22" /> 50.77%
                      </h6>
                      <h6 className="rajdhani-600 mb-0 text-gray-500 font-xs lh-22">
                        432 Purchase
                      </h6>
                    </div>
                  </div>
                  <div className="col-3 ps-0">
                    <i className="btn btn-icon btn-md ms-auto alert-primary border-0 text-primary rounded-pill font-lg bi-award-fill" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end border">
                  <div className="col-9 pe-0">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm">
                      New Users
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      4.73M
                    </h2>
                    <div className="d-flex flex-row gap-2">
                      <h6 className="text-success mb-0 px-2 py-0 font-xss rajdhani-700 rounded-6 alert-success inter-700 font-xsssss d-inline-block">
                        <i className="bi-arrow-up-right lh-22" /> 50.77%
                      </h6>
                      <h6 className="rajdhani-600 mb-0 text-gray-500 font-xs lh-22">
                        432 Purchase
                      </h6>
                    </div>
                  </div>
                  <div className="col-3 ps-0">
                    <i className="btn btn-icon btn-md ms-auto alert-primary border-0 text-primary rounded-pill font-lg bi-award-fill" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end border">
                  <div className="col-9 pe-0">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm">
                      New Users
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      4.73M
                    </h2>
                    <div className="d-flex flex-row gap-2">
                      <h6 className="text-success mb-0 px-2 py-0 font-xss rajdhani-700 rounded-6 alert-success inter-700 font-xsssss d-inline-block">
                        <i className="bi-arrow-up-right lh-22" /> 50.77%
                      </h6>
                      <h6 className="rajdhani-600 mb-0 text-gray-500 font-xs lh-22">
                        432 Purchase
                      </h6>
                    </div>
                  </div>
                  <div className="col-3 ps-0">
                    <i className="btn btn-icon btn-md ms-auto alert-primary border-0 text-primary rounded-pill font-lg bi-award-fill" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end border">
                  <div className="col-9 pe-0">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm">
                      New Users
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      4.73M
                    </h2>
                    <div className="d-flex flex-row gap-2">
                      <h6 className="text-success mb-0 px-2 py-0 font-xss rajdhani-700 rounded-6 alert-success inter-700 font-xsssss d-inline-block">
                        <i className="bi-arrow-up-right lh-22" /> 50.77%
                      </h6>
                      <h6 className="rajdhani-600 mb-0 text-gray-500 font-xs lh-22">
                        432 Purchase
                      </h6>
                    </div>
                  </div>
                  <div className="col-3 ps-0">
                    <i className="btn btn-icon btn-md ms-auto alert-primary border-0 text-primary rounded-pill font-lg bi-award-fill" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end border">
                  <div className="col-9 pe-0">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm">
                      New Users
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      4.73M
                    </h2>
                    <div className="d-flex flex-row gap-2">
                      <h6 className="text-success mb-0 px-2 py-0 font-xss rajdhani-700 rounded-6 alert-success inter-700 font-xsssss d-inline-block">
                        <i className="bi-arrow-up-right lh-22" /> 50.77%
                      </h6>
                      <h6 className="rajdhani-600 mb-0 text-gray-500 font-xs lh-22">
                        432 Purchase
                      </h6>
                    </div>
                  </div>
                  <div className="col-3 ps-0">
                    <i className="btn btn-icon btn-md ms-auto alert-primary border-0 text-primary rounded-pill font-lg bi-award-fill" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* middle wrap */}
          {/* right side */}

          {/* right side */}
          <button className="btn btn-circle text-white btn-neutral sidebar-right bg-gray-200">
            <i className="bi-chevron-compact-left text-gray-900" />
          </button>
        </div>
      </div>
    </div>
  );
}
