import React from "react";

export default function StakingYield() {
  return (
    <>
      <div className="feature-wrapper black-bg ">
        <div className="container pt-100">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <img
                src="assets/images/staking/start_staking_image.png"
                alt="banner"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 col-md-12 ms-auto md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 pe-lg-5">
                Start Staking Today!
              </h2>
              <h6 className="text-gray-700 dark-text rajdhani-500 lh-30 pe-lg-5 me-lg-5">
                Join the growing Onmax Staking community and turn your crypto
                holdings into a steady income stream. Whether you’re new to
                staking or a seasoned investor, Onmax makes it easy to grow your
                wealth while contributing to the decentralized finance
                ecosystem.
              </h6>
              {/* <h6 className="text-gray-700 dark-text rajdhani-500 lh-30 pe-lg-5 me-lg-5">
                Onmax Token (OM), Ethereum (ETH), Solana (SOL), Tether (USDT)
                BNB Coin (BNB), Tron (TRX) Dai (DAI), Avalanche (AVAX), Cardano
                (ADA)
              </h6> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
