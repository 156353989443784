import React from 'react'
import Header from '../Coman/Header'
import BotsHero from './BotsHero'
import Bnews from './Bnews'
import Footer from '../Coman/Footer'
import BFuture from './BFuture'
import BCard from './BCard'
import BBox from './BBox'

export default function Bots() {
  return (
    <div>
        <Header/>
        <BotsHero/>
        <BFuture/>
        <BBox/>
        <BCard/>

        <Bnews/>
        <Footer/>
    </div>
  )
}
