import React from 'react'
import Header from '../Coman/Header'
import EcosystemHero from './EcosystemHero'
import EcosystemdApps from './EcosystemdApps'
import Footer from '../Coman/Footer'
import Stay from './Stay'

export default function Ecosystem() {
  return (
    <>
        <Header/>
        <EcosystemHero/>
        <EcosystemdApps/>
        <Stay/>
        <Footer/>
    </>
  )
}
