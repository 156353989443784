import React from "react";

export default function Gworks() {
  return (
    <div>
      <div className="nft-wrapper pb-5 pt-100 black-bg  ">
        <div className="container-wide pb-lg-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center d-flex justify-content-center gap-2 pb-75">
              <h2 className="text-gray-900 rajdhani-600 h1 mb-0 dark-text">
                How Onmax Governance Works
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 md-mb-3 d-flex flex-column justify-content-center text-center">
              <div className="rounded-15 new-pink btn btn-icon btn-lg mx-auto text-white font-lg p-2">
                <img src="assets/images/gov/get_omp.svg" alt="" />
              </div>

              <h6 className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text">
                Get OM
              </h6>
              <p className="text-gray-900 dark-text rajdhani-500 font-sm px-lg-4 lh-26 mb-0">
                Acquire OMtokens to be eligible for voting. It is a gateway to
                Onmax governance.
              </p>
            </div>
            <div className="col-lg-3 md-mb-3 d-flex flex-column justify-content-center text-center">
              <div className="rounded-15 new-pink btn btn-icon btn-lg mx-auto text-white font-lg p-2">
                <img src="assets/images/gov/propose.svg" alt="" />
              </div>

              <h6 className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text">
                Propose
              </h6>
              <p className="text-gray-900 dark-text rajdhani-500 font-sm px-lg-4 lh-26 mb-0">
                If you want to propose a change, stake OMdeposit & suggest the
                topic to council.
              </p>
            </div>
            <div className="col-lg-3 md-mb-3 d-flex flex-column justify-content-center text-center">
              <div className="rounded-15 new-pink btn btn-icon btn-lg mx-auto text-white font-lg p-2">
                <img src="assets/images/gov/discuss.svg" alt="" />
              </div>

              <h6 className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text">
                Discuss
              </h6>
              <p className="text-gray-900 dark-text rajdhani-500 font-sm px-lg-4 lh-26 mb-0">
                Join the community of governing members’ forum to discuss
                ongoing proposals.
              </p>
            </div>
            <div className="col-lg-3 md-mb-3 d-flex flex-column justify-content-center text-center">
              <div className="rounded-15 new-pink btn btn-icon btn-lg mx-auto text-white font-lg p-2">
                <img src="assets/images/gov/vote.svg" alt="" />
              </div>

              <h6 className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text">
                Vote
              </h6>
              <p className="text-gray-900 dark-text rajdhani-500 font-sm px-lg-4 lh-26 mb-0">
                Use your OMto cast votes on proposals that support development
                of the ecosystem.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
