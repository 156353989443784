import React from "react";

export default function Stakingcrypto() {
  return (
    <>
      <div className="container-large  pt-100 pb-100">
        <div className="row justify-content-center">
          <div className="col-lg-9   mb-lg-5 mb-4 ">
            <h2 className="text-gray-900 rajdhani-600 h1 mb-0 dark-text text-center">
              How Does Onmax Staking Work?
            </h2>
            {/* <p className="rajdhani-600 dark-text h6 lh-28 text-gray-900 w-md-75 mt-2 pe-md-5 text-center">
              Onmax’s approach to yield farming is designed to ensure that users
              get the most out of their investments. The platform does this
              through several innovative features:
            </p> */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-4">
            <div
              className="d-flex p-4 rounded-10 flex-column  new-pink-bg"
              // style={{ background: "#ff44ff2e" }}
            >
              <img
                src="assets/images/staking/choose_your_asset.svg"
                width={60}
                height={60}
                alt=""
              />
              <h2 className="rajdhani-600 text-gray-900 h5 my-2 text-white">
                Choose Your Asset
              </h2>
              <p className="text-gray-600 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 text-white  mb-3">
                Select from a range of supported cryptocurrencies available for
                staking on the Onmax platform.
              </p>
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <div
              className="d-flex p-4 rounded-10 flex-column new-pink-bg"
              // style={{ background: "#ECFFF6" }}
            >
              <img
                src="assets/images/staking/deposit_and_stake.svg"
                width={60}
                height={60}
                alt=""
              />
              <h2 className="rajdhani-600 text-gray-900 h5 my-2 text-white ">
                Deposit and Stake
              </h2>
              <p className="text-gray-600 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 mb-0 text-white ">
                Deposit your assets into the staking pool & confirm period.
                You’ll start earning rewards based on the amount & duration.
              </p>
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <div
              className="d-flex p-4 rounded-10 flex-column new-pink-bg"
              // style={{ background: "#FFF5EB" }}
            >
              <img
                src="assets/images/staking/earn_rewards.svg"
                width={60}
                height={60}
                alt=""
              />
              <h2 className="rajdhani-600 text-gray-900 h5 my-2 text-white ">
                Earn Rewards
              </h2>
              <p className="text-gray-600 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 mb-3 text-white ">
                Watch your assets grow as Onmax distributes staking rewards
                directly to your wallet on a regular basis.
              </p>
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <div
              className="d-flex p-4 rounded-10 flex-column new-pink-bg"
              // style={{ background: "#FFF5EB" }}
            >
              <img
                src="assets/images/staking/unstake_anytime.svg"
                width={60}
                height={60}
                alt=""
              />
              <h2 className="rajdhani-600 text-gray-900 h5 my-2 text-white ">
                Unstake Anytime
              </h2>
              <p className="text-gray-600 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 mb-3 text-white ">
                Once your staking period ends, you can easily unstake your
                assets or reinvest them to continue earning rewards.
              </p>
            </div>
          </div>
        <div>
        <a
            href="#"
            className="btn btn-lg text-white rajdhani-600 rounded-6 bg-gray-900 border-dark border-2 px-lg-5 font-sm text-uppercase"
          >
            Stake Now
          </a>
        </div>
        </div>
      </div>
    </>
  );
}
