import React from "react";

export default function Awesome() {
  return (
    <div>
      <div className="roadmap-wrapper black-bg pt-100 pb-100">
        <div className="container">
          <div className="row justify-content-between mb-75">
            <div className="col-md-6 md-mb-3">
              <h2 className="display1-size dark-text lh-1 mb-0 text-gray-900 rajdhani-700">
                Move to the existent ecosystem
              </h2>
            </div>
            <div className="col-md-5 mb-1 mt-auto">
              <p className="px-md-5 dark-text text-gray-800 rajdhani-600 font-sm mb-0">
                Onmax provides seamless navigation across the DeFi landscape,
                enabling efficient interaction with decentralized protocols and
                assets.
              </p>
            </div>
          </div>
          <div className="pt-4 pb-4 border-bottom border-2 border-light">
            <div className="row align-items-center">
              {/* <div className="col-sm-1 posr col-2">
                <h6
                  className="text-gray-900 dark-text rajdhani-700 text-uppercase font-sm start-0 posa"
                  style={{ transform: "rotate(90deg)", top: "15px" }}
                >
                  03 SEP
                </h6>
              </div> */}
              <div className="col-sm-5 col-10">
                <h3 className="rajdhani-700 dark-text text-gray-900 h5 mb-0 mt-0">
                  Accurate & Automated Trading
                </h3>
                {/* <p className="text-gray-800 dark-text rajdhani-600 font-xs mb-0">
                  Otherdeed is the key to claiming land
                </p> */}
              </div>
              <div className="col-sm-6 col-12 sm-mt-3">
                <p className="text-gray-800 dark-text rajdhani-600 font-sm mb-0 mt-1">
                  Enjoy precision in trading with automated bots that optimize
                  performance based on real-time market data, minimizing risks
                  and maximizing returns.
                </p>
              </div>
              <div className="col-sm-1 col-12 h-45 ovh posr mt-2 sm-mt-3">
                <i className="bi-arrow-up-right dark-text  h1 lh-32 m-0" />
              </div>
            </div>
          </div>
          <div className="pt-4 pb-4 border-bottom border-2 border-light">
            <div className="row align-items-center">
              <div className="col-sm-5 col-10">
                <h3 className="rajdhani-700 dark-text text-gray-900 h5 mb-0 mt-0">
                  Pooled Liquidity
                </h3>
                {/* <p className="text-gray-800 dark-text rajdhani-600 font-xs mb-0">
                  Otherdeed is the key to claiming land
                </p> */}
              </div>
              <div className="col-sm-6 col-12 sm-mt-3">
                <p className="text-gray-800 dark-text rajdhani-600 font-sm mb-0 mt-1">
                  Access deep, pooled liquidity for smoother trades with minimal
                  slippage, ensuring fast and efficient transactions across
                  multiple markets.
                </p>
              </div>
              <div className="col-sm-1 col-12 h-45 ovh posr mt-2 sm-mt-3">
                <i className="bi-arrow-up-right dark-text  h1 lh-32 m-0" />
              </div>
            </div>
          </div>
          <div className="pt-4 pb-4 border-bottom border-2 border-light">
            <div className="row align-items-center">
              <div className="col-sm-5 col-10">
                <h3 className="rajdhani-700 dark-text text-gray-900 h5 mb-0 mt-0">
                  Industry Standard Yield
                </h3>
                {/* <p className="text-gray-800 dark-text rajdhani-600 font-xs mb-0">
                  Otherdeed is the key to claiming land
                </p> */}
              </div>
              <div className="col-sm-6 col-12 sm-mt-3">
                <p className="text-gray-800 dark-text rajdhani-600 font-sm mb-0 mt-1">
                  Earn competitive yields that align with industry benchmarks,
                  maximizing returns on your investments through proven,
                  sustainable strategies.{" "}
                </p>
              </div>
              <div className="col-sm-1 col-12 h-45 ovh posr mt-2 sm-mt-3">
                <i className="bi-arrow-up-right dark-text h1 lh-32 m-0" />
              </div>
            </div>
          </div>
          <div className="pt-4 pb-4 border-bottom border-2 border-light">
            <div className="row align-items-center">
              <div className="col-sm-5 col-10">
                <h3 className="rajdhani-700 dark-text text-gray-900 h5 mb-0 mt-0">
                  Multichain & Wide Asset Support
                </h3>
              </div>
              <div className="col-sm-6 col-12 sm-mt-3">
                <p className="text-gray-800 dark-text rajdhani-600 font-sm mb-0 mt-1">
                  {" "}
                  Trade and invest across multiple blockchains with support for
                  a wide range of assets, giving you unmatched flexibility and
                  choice.
                </p>
              </div>
              <div className="col-sm-1 col-12 h-45 ovh posr mt-2 sm-mt-3">
                <i className="bi-arrow-up-right dark-text  h1 lh-32 m-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
