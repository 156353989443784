import {  createContext, useEffect, useState } from "react";
export const myContext = createContext(null);

const MyContext = ({ children }) => {

    // const [theme, setTheme] = useState(
    //     localStorage.getItem("theme") ? localStorage.getItem("theme") : "light-mode"
    //   );
    //   const toggleTheme = () => {
    //     if (theme === "light-mode") {
    //       setTheme("dark-mode");
    //     } else {
    //       setTheme("light-mode");
    //     }
    //   };
    
     
    //   useEffect(() => {
    //     document.body.className = theme;
    //     localStorage.setItem("theme", theme);
    //   }, [theme]);
    
  return (
    <div>
 <myContext.Provider value={{  
        // theme,
        // setTheme,
        // toggleTheme,
     }}>
      {children}
    </myContext.Provider>
    </div>
  )
}

export default MyContext;

