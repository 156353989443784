import React from "react";

export default function TradingHero() {
  return (
    <>
  <div className="banner-wrapper dark-bg pt--lg-100 bg-no-repeat bg-image-right bg-image-none-dark" style={{backgroundImage: 'url(assets/images/tra/bg-18.png)'}}>
  <div className="container py-lg-5 pb-4">
    <div className="row posr">
      <div className="col-12">
        <h3 className="text-gray-900 rajdhani-700 dark-text ls-1 mb-0 display5-size lh-1">Explore the finest crypto trading facility 
        </h3>
      </div>
      <div className="col-md-5">
        <h3 className="text-gray-900 rajdhani-700 dark-text ls-1 mb-0 display6-size lh-1"></h3>
      </div>
      <div className="col-md-6 ps-lg-5 ps-md-4 ps-3">
        <div className="d-flex flex-row gap-3 mt-lg-4 pt-3">
          {/* <a href="#" className="btn btn-lg dark-white text-white light-text rajdhani-600 rounded-6 bg-gray-900 border-dark border-2 px-xl-5 font-sm text-uppercase">Start Now</a> */}
        </div>
      </div>
      <div className="col-8 mb-md-4 sm-mt-3">
        <p className="rajdhani-600 dark-text ps-md-2 font-md lh-32 text-gray-900 w-lg-75 mt-2 pe-lg-5">Onmax offers a flexible & personalized approach to trading crypto assets while providing different levels of experience and risk appetite.
        </p>
        <a href="#" className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 px-xl-5 font-sm text-uppercase">Start Now</a>

      </div>
      {/* <img src="assets/images/round-text-2.png" alt="icon" className="posa right-0 bottom-0 w-150 rotate-circle" /> */}
    </div>
  </div>
  <div className="border-light border-top border-2 dark-border2" />
  <div className="container">
    <div className="row mx-0 align-items-center">
      <div className="col-sm-4 sm-mt-3 border-start dark-border2 border-2 border-end border-light sm-border-0">
        <div className="banner-feature p-lg-5 p-3">
          {/* <h4 className="rajdhani-600 font-md lh-24 text-gray-900 dark-text">Connect with <br />more people</h4> */}
          {/* <div className="d-flex justify-content-start mt-4">
            <figure className="w-55 mb-0 ms-0"><img src="assets/images/avater-4.png" alt="avater" className="w-100" /></figure>
            <figure className="w-55 mb-0 ms-n4"><img src="assets/images/avater-3.png" alt="avater" className="w-100" /></figure>
            <figure className="w-55 mb-0 ms-n4"><img src="assets/images/avater-2.png" alt="avater" className="w-100" /></figure>
            <figure className="w-55 mb-0 ms-n4"><img src="assets/images/n-2.png" alt="avater" className="w-100" /></figure>
          </div> */}
          <a href="#" className="btn btn-lg text-gray-900 rajdhani-700 dark-text px-0 mt-5 py-0 bg-transparent border-0 font-md text-uppercase">Join as creater <i className="bi-arrow-up-right mt-n2 ms-1" /></a>
        </div>
      </div>
      <div className="col-sm-4 sm-mt-3 border-2 dark-border2 border-end border-light sm-border-0 mt-md-3">
      <div className="banner-feature px-lg-5 pt-lg-3 pb-lg-5 p-3">
          <h4 className="rajdhani-600 lh-2 dark-text">100+ <br /> Traders 
          </h4>
        </div>
      </div>
      <div className="col-sm-4 sm-mt-3 border-2 dark-border2 border-end border-light sm-border-0 mt-md-3">
        <div className="banner-feature px-lg-5 pt-lg-3 pb-lg-5 p-3">
          <h4 className="rajdhani-600 lh-2 dark-text">$100k+ <br />Trading Volume </h4>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  );
}
