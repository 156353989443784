import React from "react";

export default function Bots() {
  return (
    <>
      <div className="main-wrapper bg-image-cover bg-image-center">
        <div className="container mb-3">
          <div className="row mt-4">
            <div className="d-flex align-items-center justify-content-center">
              <div className="col-lg-8">
                <div className="shadow-lg p-lg-4 p-3 text-center rounded-10 bg-white w-100">
                  <div className="d-flex text-start flex-column mt-5">
                    <h1 className="rajdhani-700 mt-0 mb-0 text-gray-800 ls-0 lh-1 h2">
                      Bots
                    </h1>
                    <form className="form-style-floating style2 text-center mt-4">
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className="form-control rounded-10 font-xsss text-gray-800 rajdhani-600"
                          id="floatingEmail"
                          placeholder="Referral Address"
                        />
                        <label htmlFor="floatingEmail">Referral Address</label>
                      </div>
                    </form>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 mt-2">
        <label className="form-label rajdhani-600 text-gray-700">
          Choose your account
        </label>
        <div className="d-flex flex-row gap-2 mb-3 flex-wrap">
          <div className="form-radio w-200">
            <input
              type="radio"
              className="btn-check"
              name="bank"
              id="bank-visa"
              defaultChecked
            />
            <label
              className="p-3 border-light dark-border border payment-check check-top btn-md btn-block rounded-10 d-flex flex-column position-relative border-2 border-light inter-600 text-gray-700 text-start justify-content-start"
              htmlFor="bank-visa"
            >
              <img src="assets/images/b-10.png" alt="card" className="w-35" />
              <span className="mt-3 mb-0 dark-text text-gray-900 h6 rajdhani-600">
                Debit Card
              </span>
              <span className="rajdhani-500 dark-text text-gray-500 lh-24 mb-0 font-xs">
                2324 **** 3432
              </span>
            </label>
          </div>
          <div className="form-radio w-200">
            <input
              type="radio"
              className="btn-check"
              name="bank"
              id="bank-card"
            />
            <label
              className="p-3 border-light dark-border border payment-check check-top btn-md btn-block rounded-10 d-flex flex-column position-relative border-2 border-light inter-600 text-gray-700 text-start justify-content-start"
              htmlFor="bank-card"
            >
              <img src="assets/images/b-12.png" alt="card" className="w-35" />
              <span className="mt-3 mb-0 h6 dark-text text-gray-900 rajdhani-600">
                Visa Card
              </span>
              <span className="rajdhani-500 dark-text text-gray-500 lh-24 mb-0 font-xs">
                2324 **** 4432
              </span>
            </label>
          </div>
          <div className="form-radio w-200">
            <input
              type="radio"
              className="btn-check"
              name="bank"
              id="bank-card-1"
            />
            <label
              className="p-3 border-light dark-border border payment-check check-top btn-md btn-block rounded-10 d-flex flex-column position-relative border-2 border-light inter-600 text-gray-700 text-start justify-content-start"
              htmlFor="bank-card-1"
            >
              <img src="assets/images/b-12.png" alt="card" className="w-35" />
              <span className="mt-3 mb-0 h6 dark-text text-gray-900 rajdhani-600">
                Visa Card
              </span>
              <span className="rajdhani-500 dark-text text-gray-500 lh-24 mb-0 font-xs">
                2324 **** 4432
              </span>
            </label>
          </div>
          <div className="form-radio w-200">
            <input
              type="radio"
              className="btn-check"
              name="bank"
              id="bank-card-2"
            />
            <label
              className="p-3 border-light dark-border border payment-check check-top btn-md btn-block rounded-10 d-flex flex-column position-relative border-2 border-light inter-600 text-gray-700 text-start justify-content-start"
              htmlFor="bank-card-2"
            >
              <img src="assets/images/b-12.png" alt="card" className="w-35" />
              <span className="mt-3 mb-0 h6 dark-text text-gray-900 rajdhani-600">
                Visa Card
              </span>
              <span className="rajdhani-500 dark-text text-gray-500 lh-24 mb-0 font-xs">
                2324 **** 4432
              </span>
            </label>
          </div>
          <div className="form-radio w-200">
            <input
              type="radio"
              className="btn-check"
              name="bank"
              id="bank-card-3"
            />
            <label
              className="p-3 border-light dark-border border payment-check check-top btn-md btn-block rounded-10 d-flex flex-column position-relative border-2 border-light inter-600 text-gray-700 text-start justify-content-start"
              htmlFor="bank-card-3"
            >
              <img src="assets/images/b-12.png" alt="card" className="w-35" />
              <span className="mt-3 mb-0 h6 dark-text text-gray-900 rajdhani-600">
                Visa Card
              </span>
              <span className="rajdhani-500 dark-text text-gray-500 lh-24 mb-0 font-xs">
                2324 **** 4432
              </span>
            </label>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="card-body p-0 border-0 shadown-none d-flex flex-row pb-3 mb-3 border-light border-bottom dark-border">
            <h5 className="text-gray-900 font-xl rajdhani-600 my-2 lh-24 dark-text">
              Theme Settings
            </h5>
          </div>
          <div className="card-body p-0 border-0 mb-3">
            <div className="row">
              <div className="col-12">
                <div className="mb-3 form-group">
                  <label className="form-label rajdhani-600 text-gray-700">
                    Choose theme color
                  </label>
                  <div className="switchcolor-wrap mt-0 flex-row d-flex">
                    <a
                      className="color-theme-primary"
                      data-theme-color="primary"
                    >
                      <span />
                    </a>
                    <a className="color-theme-blue" data-theme-color="blue">
                      <span />
                    </a>
                    <a className="color-theme-cyan" data-theme-color="cyan">
                      <span />
                    </a>
                    <a className="color-theme-teal" data-theme-color="teal">
                      <span />
                    </a>
                    <a className="color-theme-green" data-theme-color="green">
                      <span />
                    </a>
                    <a className="color-theme-lime" data-theme-color="lime">
                      <span />
                    </a>
                    <a
                      className="color-theme-yellow active"
                      data-theme-color="yellow"
                    >
                      <span />
                    </a>
                    <a className="color-theme-orange" data-theme-color="orange">
                      <span />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-check form-switch switch-lg switch-success p-0 d-flex flex-row justify-content-between">
                  <label
                    className="form-check-label w-75 font-sm rajdhani-700 text-gray-900 dark-text lh-28"
                    htmlFor="darkmodeswitch2"
                  >
                    Dark Mode{" "}
                    <span className="font-xss rajdhani-500 text-gray-500 mt-n1">
                      Dont allow checkbox use when you access the option.
                    </span>
                  </label>
                  <input
                    className="form-check-input mt-3"
                    data-switch-theme
                    type="checkbox"
                    id="darkmodeswitch2"
                  />
                </div>
              </div>
              <div className="col-12">
                <a
                  href="#"
                  className="btn btn-md btn-block text-gray-900 text-uppercase rajdhani-700 rounded-10 bg-current  font-xs mt-3 mb-4"
                >
                  Save
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
