import React from 'react'
import Header from '../Coman/Header'
import Footer from '../Coman/Footer'
import FaqHero from './FaqHero'
import FaqGeneral from './FaqGeneral'
import BotsFaq from './BotsFaq'
import FarmingFaq from './FarmingFaq'
import StakingFaq from './StakingFaq'
import TokenFaq from './TokenFaq'
import NFTsFaq from './NFTsFaq'

export default function Faq() {
  return (
    <div>
        <Header/>
        <FaqHero/>
        <FaqGeneral/>
        <BotsFaq/>
        <FarmingFaq/>
        <StakingFaq/>
        <TokenFaq/>
        <NFTsFaq/>
        <Footer/>

    </div>
  )
}
