import React from "react";

export default function TCreate() {
  return (
    <div>
      <div className="feature-wrapper pt-100">
        <div className="container ">
          <div className="row justify-content-center ">
            <div className="col-lg-8 text-center page-nav">
              <h2 className="text-gray-900 dark-text lh-2 mb-2 mt-0 rajdhani-600 h1">
                Onmax Trading Bots
              </h2>
              {/* <p className="dark-text text-gray-800 rajdhani-600 font-sm px-5">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid non cupidatat skateboard dolor brunch.</p> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-3">
              <div className="icon-div d-flex flex-column text-center posr  rounded-10">
               <div className="img-hooo">
               <img src="assets/images/tra/yellow_fox.png" className="img-ho rounded-20" alt="" /></div> <br />
                <h4 className="h5 text-gray-900 dark-text rajdhani-600"> 
                  Yellow Fox
                </h4>
                <p className="dark-text text-gray-800 rajdhani-600 font-sm px-lg-4">
                  Yellow Fox is Onmax's entry-level trading bot, making it the
                  perfect starting point for users new to decentralized finance
                  and automated trading. This bot is designed with simplicity in
                  mind, allowing users to get accustomed to the platform without
                  needing extensive technical knowledge or a deep understanding
                  of complex trading strategies.
                </p>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="icon-div d-flex flex-column text-center posr">
              <div className="img-hooo">
              <img src="assets/images/tra/brown_fox.png" className="img-ho rounded-20" alt="" /></div> <br />
                <h4 className="h5 text-gray-900 dark-text rajdhani-600">
                  Brown Fox
                </h4>
                <p className="dark-text text-gray-800 rajdhani-600 font-sm px-lg-4">
                  For those who have gained some experience in DeFi trading and
                  are ready to take their strategies to the next level, the
                  Brown Fox trading bot is the ideal choice. Brown Fox builds on
                  the foundation set by Yellow Fox, offering more exclusive
                  trading features that cater to users seeking increased profit
                  potential while maintaining a balanced approach to risk.
                </p>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="icon-div d-flex flex-column text-center posr">
              <div className="img-hooo">
              <img src="assets/images/tra/black_fox.png" className="img-ho rounded-20" alt="" /></div> <br />
                <h4 className="h5 text-gray-900 dark-text rajdhani-600">
                  Black Fox
                </h4>
                <p className="dark-text text-gray-800 rajdhani-600 font-sm px-lg-4">
                  Black Fox trading bot represents the premium option in Onmax’s
                  suite of trading bots, specifically designed for users looking
                  to unlock the highest profit potentials. Tailored for advanced
                  traders and experienced DeFi enthusiasts, Black Fox provides
                  access to cutting-edge trading strategies that focus on
                  maximizing returns from even the most volatile markets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
