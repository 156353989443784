import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { myContext } from "../../MyContext";

export default function Sidebar() {
  const { theme, toggleTheme } = useContext(myContext);

  const currentPath = window.location.pathname;

  return (
    <div>
      <nav className="navigation scroll-bar">
        <div className="nav-content h-100 d-flex flex-column">
          <div className="nav-top">
            {/* <Link className="navbar-brand light-logo" to="/">
              <img
              src="assets/images/hero/logo_white.svg"
                alt="logo"
              />
            </Link> */}
            <Link className="navbar-brand dark-logo" to="/">
              <img
                src="assets/images/hero/logo_black.svg"
                alt="logo"
                width={180}
              />
            </Link>
            <a href="#" className="close-nav d-flex d-lg-none">
              <i className="feather-x bg-gray-200 mb-4 btn-round-sm font-lg p-2 rounded-pill text-dark ms-auto me-2 mt-4" />
            </a>
          </div>
          {/* <div className="nav-caption inter-500 font-xsss text-gray-500">
            General
          </div> */}
          <ul className="mb-3">
            <li className="logo d-none d-xl-block d-lg-block" />
            <li>
              <Link to="/dashboard" className="nav-content-bttn gap-2">
                <i className="bi-grid-fill" />
                <span
                  className={`${
                    currentPath === "/dashboard"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  {" "}
                  Dashboard
                </span>
              </Link>
              {/* <ul className="sub-menu" style={{ display: "none" }}>
                <li className="menu-item">
                  <a href="dashboard-style-1.html">Dashboard Style 1</a>
                </li>
                <li className="menu-item">
                  <a href="dashboard-style-2.html">Dashboard Style 2</a>
                </li>
              </ul> */}
            </li>
            <li>
              <a href="#" className="nav-content-bttn mega-menu gap-2">
                <i className="feather-zap" />
                <span>Staking</span>
              </a>
              {/* <ul className="sub-menu" style={{ display: "none" }}>
                <li className="menu-item">
                  <a href="dashboard-author-1.html">Author Style 1</a>
                </li>
                <li className="menu-item">
                  <a href="dashboard-author-2.html">Author Style 2</a>
                </li>
              </ul> */}
            </li>
            <li>
              <a href="#" className="nav-content-bttn gap-2 mega-menu">
                <i className="feather-globe" />
                <span>Team</span>
              </a>
              {/* <ul className="sub-menu" style={{ display: "none" }}>
                <li className="menu-item">
                  <a href="dashboard-explore-1.html">NFT Style 1</a>
                </li>
                <li className="menu-item">
                  <a href="dashboard-explore-2.html">NFT Style 2</a>
                </li>
                <li className="menu-item">
                  <a href="dashboard-explore-3.html">NFT Style 3</a>
                </li>
                <li className="menu-item">
                  <a href="dashboard-explore-4.html">NFT Style 4</a>
                </li>
                <li className="menu-item">
                  <a href="dashboard-singel-1.html">Single NFT 1</a>
                </li>
                <li className="menu-item">
                  <a href="dashboard-singel-2.html">Single NFT 2</a>
                </li>
              </ul> */}
            </li>
            <li>
              <Link to="/staking-reward" className="nav-content-bttn gap-2 ">
                <i className="feather-box" />
                <span
                  className={`${
                    currentPath === "/staking-reward"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Staking Reward
                </span>
              </Link>
              {/* <ul className="sub-menu" style={{ display: "none" }}>
                <li className="menu-item">
                  <a href="dashboard-profile-1.html">Profile Style 1</a>
                </li>
                <li className="menu-item">
                  <a href="dashboard-profile-2.html">Profile Style 2</a>
                </li>
              </ul> */}
            </li>
            <li className="flex-lg-brackets">
              <Link to="/aggregator-ranks" className="nav-content-bttn gap-2">
                <i className="feather-command" />
                <span
                  className={`${
                    currentPath === "/aggregator-ranks"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Aggregator Ranks
                </span>
              </Link>
            </li>
            <li className="flex-lg-brackets">
              <Link to="/super-reward" className="nav-content-bttn gap-2">
                <i className="feather-command" />
                <span
                  className={`${
                    currentPath === "/super-reward"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Super Reward
                </span>
              </Link>
            </li>
            <li className="flex-lg-brackets">
              <Link to="/validator-reward" className="nav-content-bttn gap-2">
                <i className="feather-command" />
                <span
                  className={`${
                    currentPath === "/validator-reward"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Validator Reward{" "}
                </span>
              </Link>
            </li>
            <li className="flex-lg-brackets">
              <Link to="/cold-reward" className="nav-content-bttn gap-2">
                <i className="feather-command" />
                <span
                  className={`${
                    currentPath === "/cold-reward"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Cold Reward{" "}
                </span>
              </Link>
            </li>

            <li className="flex-lg-brackets">
              <Link to="/rank-reward" className="nav-content-bttn gap-2">
                <i className="feather-command" />
                <span
                  className={`${
                    currentPath === "/rank-reward"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Rank Reward{" "}
                </span>
              </Link>
            </li>
            <li className="flex-lg-brackets">
              <a href="#" className="nav-content-bttn gap-2">
                <i className="feather-command" />
                <span>Withdrawal</span>
              </a>
            </li>
            <li className="flex-lg-brackets">
              <a href="/ledger" className="nav-content-bttn gap-2">
                <i className="feather-command" />
                <span
                  className={`${
                    currentPath === "/ledger"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Ledger
                </span>
              </a>
            </li>
            <li className="flex-lg-brackets">
              <Link to="/unstake" className="nav-content-bttn gap-2">
                <i className="feather-command" />
                <span
                  className={`${
                    currentPath === "/unstake"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Unstake
                </span>
              </Link>
            </li>
            <li className="flex-lg-brackets">
              <a
                href="dashboard-create.html"
                className="nav-content-bttn gap-2"
              >
                <i className="feather-command" />
                <span>Presentation</span>
              </a>
            </li>
            <li className="flex-lg-brackets">
              <a
                href="dashboard-create.html"
                className="nav-content-bttn gap-2"
              >
                <i className="feather-command" />
                <span>Help</span>
              </a>
            </li>
            <li className="flex-lg-brackets">
              <Link to="/locker" className="nav-content-bttn gap-2">
                <i className="feather-command" />
                <span
                  className={`${
                    currentPath === "/locker"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Locker
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className="dash-main-content me-md-3">
        <div className="middle-sidebar-header d-flex justify-content-between">
          <button className="header-menu" />
          <h4 className="rajdhani-600 ls-0 m-0 d-none d-xxl-block dark-text">
            
          </h4>

          <ul className="d-flex right-menu-icon align-items-center">
            <li>
              <a
                href="#"
                className="btn btn-md text-gray-900 rajdhani-600 rounded-10 bg-current px-4 h5 rajdhani-700 py-1 font-sm"
              >
                {" "}
                $ 0 OMP
              </a>
            </li>
            <li>
              <a
                href="#"
                className="btn btn-md text-gray-900 rajdhani-600 rounded-10 bg-current px-4 h5 rajdhani-700 py-1 font-sm"
              >
                {" "}
                $ 0
              </a>
            </li>

            <li>
              <a href="#">
                <span className="dot-count bg-warning" />
                <i className="feather-bell font-xl text-gray-700 dark-text" />
                <div className="menu-dropdown">
                  <h4 className="fw-700 mb-4 h5">Notification</h4>
                  <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                    <img
                      src="assets/images/avater-1.png"
                      alt="user"
                      className="w-40 position-absolute left-0"
                    />
                    <h5 className="font-sm ls-1 d-flex rajdhani-600 text-gray-900 dark-text mb-1 mt-0 d-block">
                      Hendrix Stamp{" "}
                      <span className="text-gray-500 font-xss rajdhani-600 ms-auto  mt-1">
                        {" "}
                        3 min
                      </span>
                    </h5>
                    <h6 className="text-gray-500 rajdhani-600 font-xss lh-2">
                      There are many variations of pass..
                    </h6>
                  </div>
                  <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                    <img
                      src="assets/images/avater-2.png"
                      alt="user"
                      className="w-40 position-absolute left-0"
                    />
                    <h5 className="font-sm ls-1 d-flex rajdhani-600 text-gray-900 dark-text mb-1 mt-0 d-block">
                      Goria Coast{" "}
                      <span className="text-gray-500 font-xss rajdhani-600 ms-auto  mt-1">
                        {" "}
                        2 min
                      </span>
                    </h5>
                    <h6 className="text-gray-500 rajdhani-600 font-xss lh-2">
                      Mobile Apps UI Designer is require..
                    </h6>
                  </div>
                  <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                    <img
                      src="assets/images/avater-3.png"
                      alt="user"
                      className="w-40 position-absolute left-0"
                    />
                    <h5 className="font-sm ls-1 d-flex rajdhani-600 text-gray-900 dark-text mb-1 mt-0 d-block">
                      Surfiya Zakir{" "}
                      <span className="text-gray-500 font-xss rajdhani-600 ms-auto  mt-1">
                        {" "}
                        1 min
                      </span>
                    </h5>
                    <h6 className="text-gray-500 rajdhani-600 font-xss lh-2">
                      Mobile Apps UI Designer is require..
                    </h6>
                  </div>
                  <div className="card bg-transparent-card w-100 border-0 ps-5">
                    <img
                      src="assets/images/avater-4.png"
                      alt="user"
                      className="w-40 position-absolute left-0"
                    />
                    <h5 className="font-sm ls-1 d-flex rajdhani-600 text-gray-900 dark-text mb-1 mt-0 d-block">
                      Victor Exrixon{" "}
                      <span className="text-gray-500 font-xss rajdhani-600 ms-auto  mt-1">
                        {" "}
                        30 sec
                      </span>
                    </h5>
                    <h6 className="text-gray-500 rajdhani-600 font-xss lh-2">
                      Mobile Apps UI Designer is require..
                    </h6>
                  </div>
                </div>
              </a>
            </li>
            <li>
            <a
                className=" menu-btn btn-toggle-dark btn btn-icon btn-round border border-2 border-dark rounded-20 align-items-center" 
                checked={theme === "light-mode"}
                id="themeToggle"
                onClick={() => toggleTheme()}
              >
                <i className="bi-brightness-low font-xl " />
              </a>
            </li>
            <li>
           
              <div className="d-flex justify-content-between align-items-start gap-2">
                <figure className="avater avater-online mb-0">
                  <img
                    src="assets/images/avater-2.png"
                    alt="user"
                    className="w-40"
                  />
                </figure>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
