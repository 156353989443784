import React from 'react'
import Header from '../Coman/Header'
import TradingHero from './TradingHero'
import TraBot from './TraBot'
import TraMain from './TraMain'
import TradingBox from './TradingBox'
import TCreate from './TCreate'
import Footer from '../Coman/Footer'

export default function Trading() {
  return (
    <div>
        <Header/>
        <TradingHero/>
        <TraMain/>
        <TCreate/>

        <TraBot/>
        <TradingBox/>

        <Footer/>
    </div>
  )
}
