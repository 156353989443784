import React from "react";

export default function Works() {
  return (
    <div>
      <div className="howitwork-wrapper py-5 black-bg">
        <div className="container-wide py-lg-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center d-flex justify-content-center gap-2 pb-75">
              <h2 className="text-gray-900 rajdhani-600 h1 mb-0 dark-text">
                Plug into Onmax & Start Exploring DeFi
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 lg-mb-3 howitwork-div d-flex flex-column justify-content-center text-center">
              <div>
                <img
                  src="assets/images/hero/connect.gif"
                  className="rounded-pill"
                  width={75}
                  height={75}
                  alt=""
                />
              </div>
              <h6 className="rajdhani-600 mt-3 mb-2 h5 text-gray-900 dark-text">
                Connect
              </h6>
              <p className="text-gray-600 dark-text rajdhani-600 mt-0 font-sm px-xl-3 lh-26 mb-0">
                Get started just by connecting your wallet, no KYC needed!
              </p>
            </div>
            <div className="col-lg-3 col-md-6 lg-mb-3 howitwork-div d-flex flex-column justify-content-center text-center">
           <div>
           <img
                  src="assets/images/hero/trade_final_icon.gif"
                  className="rounded-pill"
                  width={75}
                  height={75}
                  alt=""
                />
           </div>
              <h6 className="rajdhani-600 mt-3 mb-2 h5 text-gray-900 dark-text">
                Trade
              </h6>
              <p className="text-gray-600 dark-text rajdhani-600 mt-0 font-sm px-xl-3 lh-26 mb-0">
                Purchase an Onmax Trading Bot to start executing trading
                strategies.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 lg-mb-3 howitwork-div d-flex flex-column justify-content-center text-center">
              <div>
                <img
                  src="assets/images/hero/farm.gif"
                  className="rounded-pill"
                  width={75}
                  height={75}
                  alt=""
                />
              </div>
              <h6 className="rajdhani-600 mt-3 mb-2 h5 text-gray-900 dark-text">
                Farm
              </h6>
              <p className="text-gray-600 dark-text rajdhani-600 mt-0 font-sm px-xl-3 lh-26 mb-0">
                Start farming yields & OMtokens by trading actively!
              </p>
            </div>
            <div className="col-lg-3 col-md-6 lg-mb-3 howitwork-div d-flex flex-column justify-content-center text-center">
              <div>
                <img
                  src="assets/images/hero/stake_final_icon.gif"
                  className="rounded-pill"
                  width={75}
                  height={75}
                  alt=""
                />
              </div>
              <h6 className="rajdhani-600 mt-3 mb-2 h5 text-gray-900 dark-text">
                Stake
              </h6>
              <p className="text-gray-600 dark-text rajdhani-600 mt-0 font-sm px-xl-3 lh-26 mb-0">
                Maximize rewards by securely staking your crypto holdings.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
