import React from "react";

export default function ComCard() {
  return (
    <div>
      <div className="roadmap-wrapper pt-5 pb-5">
        <div className="container-large py-lg-5">
          <div className="team1">
            <div className="team-card1">
              <a href="#">
                <div className="team-card-top1">
                  <div className="team-card-image1">
                  <img
                      src="assets/images/com/twitter.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="team-card-bottom1">
                  <h5>Twitter</h5>
                  {/* <div >Front-end developer</div> */}
                </div>
              </a>
            </div>
            <div className="team-card1">
              <a href="#">
                <div className="team-card-top1">
                  <div className="team-card-image1">
                  <img
                      src="assets/images/com/facebook.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="team-card-bottom1">
                  <h5>Facebook</h5>
                  {/* <div >UX/UI designer</div> */}
                </div>
              </a>
            </div>
            <div className="team-card1">
              <a href="#">
                <div className="team-card-top1">
                  <div className="team-card-image1">
                    <img
                      src="assets/images/com/telegram.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="team-card-bottom1">
                  <h5>Telegram</h5>
                </div>
              </a>
            </div>
            <div className="team-card1">
              <a href="#">
                <div className="team-card-top1">
                  <div className="team-card-image1">
                  <img
                      src="assets/images/com/instagram.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="team-card-bottom1">
                  <h5>Instagram</h5>
                </div>
              </a>
            </div>
            <div className="team-card1">
              <a href="#">
                <div className="team-card-top1">
                  <div className="team-card-image1">
                  <img
                      src="assets/images/com/youtube.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="team-card-bottom1">
                  <h5>YouTube</h5>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
