import React, { useState } from "react";
import Sidebar from "../../Coman/Sidebar";
import StakingBox from "./StakingBox";
import Bots from "./Bots";

export default function Stake() {
  const [showtab, setShowTab] = useState(0);

  return (
    <div>
      <Sidebar />
      <div className="dash-main-content ">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="nft-wrapper py-5">
              <div className="container portfolio-grid">
                <div className="row justify-content-center">
                  <div className="col-lg-8 text-center d-flex justify-content-center gap-2 pb-4">
                    <h2 className="text-gray-900 rajdhani-600 h1 mb-0 dark-text">
                      Explore marketplace
                    </h2>
                  </div>
                  <div className="col-md-12 mt-2 mb-3 portfolio-tab style2">
                    <ul
                      className="nft-wrapper flex-row d-md-flex mt-0 gap-2 justify-content-center dark-border"
                      id="filters"
                    >
                      <li
                        className={
                          showtab === 0
                            ? "p-0 filter active tab-sta pt-1"
                            : "p-0 filter tab-st pt-1"
                        }
                        onClick={() => setShowTab(0)}
                      >
                        <span className="rajdhani-600 ">Bots</span>
                      </li>
                      <li
                        className={
                          showtab === 1
                            ? "p-0 filter active tab-sta pt-1"
                            : "p-0 filter tab-st pt-1"
                        }
                        onClick={() => setShowTab(1)}
                      >
                        <span className="rajdhani-600 ">Staking</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <div className={showtab === 0 ? "  d-block" : "d-none "}>
                    <Bots/>
                    </div>
                  </div>
                  <div className="col-12 ">
                    <div className={showtab === 1 ? "  d-block" : "d-none "}>
                      <StakingBox />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
