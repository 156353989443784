import React from "react";

export default function Bnews() {
  return (
    <div>
      <div className="roadmap-wrapper black-bg pt-100 pb-100">
        <div className="container">
          <div className="row justify-content-between mb-75">
            <div className="col-md-6 md-mb-3">
              <h2 className="display1-size dark-text lh-1 mb-0 text-gray-900 rajdhani-700">
                Onmax Bots Go Beyond Just Trading!
              </h2>
            </div>
            <div className="col-md-5 mb-1 mt-auto">
              <p className="px-md-5 dark-text text-gray-800 rajdhani-600 font-sm mb-0">
                Use Onmax bots & unlock advanced features with premium tools,
                NFT integration, & exclusive membership benefits.
              </p>
            </div>
          </div>
          <div className="pt-4 pb-4 border-bottom border-2 border-light">
            <div className="row align-items-center">
              <div className="col-sm-5 col-10">
                <h3 className="rajdhani-700 dark-text text-gray-900 h5 mb-0 mt-0">
                  Onmax PLUS Subscription
                </h3>
              </div>
              <div className="col-sm-6 col-12 sm-mt-3">
                <p className="text-gray-800 dark-text rajdhani-600 font-sm mb-0 mt-1">
                  Onmax Bots are enhanced by the Onmax PLUS subscription, which
                  grants users access to exclusive trading signals, advanced
                  tools, and market insights. This subscription provides users
                  with cutting-edge strategies and premium materials designed to
                  optimize the performance of their bots, making them more
                  effective in capturing profitable opportunities.
                </p>
              </div>
              <div className="col-sm-1 col-12 h-45 ovh posr mt-2 sm-mt-3">
                <i className="bi-arrow-up-right dark-text  h1 lh-32 m-0" />
              </div>
            </div>
          </div>
          <div className="pt-4 pb-4 border-bottom border-2 border-light">
            <div className="row align-items-center">
              <div className="col-sm-5 col-10">
                <h3 className="rajdhani-700 dark-text text-gray-900 h5 mb-0 mt-0">
                  Exclusive NFTs
                </h3>
              </div>
              <div className="col-sm-6 col-12 sm-mt-3">
                <p className="text-gray-800 dark-text rajdhani-600 font-sm mb-0 mt-1">
                  Onmax Bots are connected with Onmax NFTs, where each NFT
                  unlocks unique bot features such as specific profit caps,
                  margin levels, and tailored trading strategies. The NFT
                  integration adds a personalized layer to the bots, allowing
                  users to benefit from enhanced functionalities depending on
                  the NFTs they own.
                </p>
              </div>
              <div className="col-sm-1 col-12 h-45 ovh posr mt-2 sm-mt-3">
                <i className="bi-arrow-up-right dark-text  h1 lh-32 m-0" />
              </div>
            </div>
          </div>
          <div className="pt-4 pb-4 border-bottom border-2 border-light">
            <div className="row align-items-center">
              <div className="col-sm-5 col-10">
                <h3 className="rajdhani-700 dark-text text-gray-900 h5 mb-0 mt-0">
                  Membership Program
                </h3>
              </div>
              <div className="col-sm-6 col-12 sm-mt-3">
                <p className="text-gray-800 dark-text rajdhani-600 font-sm mb-0 mt-1">
                  Through the Exclusive Membership, Onmax Bot users gain access
                  to VIP trading groups, where they can receive personalized
                  support, deeper market analysis, and premium trading
                  resources. This membership ensures that high-level traders get
                  extra tools and insights to further refine and maximize their
                  automated trading strategies.
                </p>
              </div>
              <div className="col-sm-1 col-12 h-45 ovh posr mt-2 sm-mt-3">
                <i className="bi-arrow-up-right dark-text h1 lh-32 m-0" />
              </div>
            </div>
          </div>
      
        </div>
      </div>
    </div>
  );
}
