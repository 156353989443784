import React from "react";
import Header from "./Coman/Header";
import Footer from "./Coman/Footer";

export default function PrivacyPolicy() {
  return (
    <>
      <Header />
      <div className="container pt-150 pb-75">
        <div className="row">
          <div className="col-12 page-nav justify-content-center d-flex">
            <h2 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 display1-size dark-text">
              Privacy Policy
            </h2>
          </div>
        </div>
        <div className="pt-md-5">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text">
            Effective Date: September 2024
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            At Onmax, we are committed to safeguarding the privacy and personal
            information of our users. This Privacy Policy outlines how we
            collect, use, disclose, and protect your information when you access
            our platform or use any of our services. By using Onmax, you agree
            to the practices described in this policy.
          </p>
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            1. Information We Collect
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            We collect the following types of information to provide and improve
            our services:
          </p>
          <ul>
            <li className="d-flex align-items-center gap-2">
              <i class="bi bi-circle-fill font-xssss dark-text "></i>
              <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                Personal Information :
              </h6>
            </li>
            <li>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                such as your name, email address, phone number, and any other
                identifying information you provide.
              </p>
            </li>
            <li className="d-flex align-items-center gap-2">
              <i class="bi bi-circle-fill font-xssss dark-text "></i>
              <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                Wallet Information :
              </h6>
            </li>
            <li>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                If you engage in transactions on Onmax, we may collect payment
                details and wallet addresses.
              </p>
            </li>
            <li className="d-flex align-items-center gap-2">
              <i class="bi bi-circle-fill font-xssss dark-text "></i>
              <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                Usage Data :
              </h6>
            </li>
            <li>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                We gather information about how you interact with the platform,
                including device information, IP address, browser type,
                operating system, and page interaction data.
              </p>
            </li>
            <li className="d-flex align-items-center gap-2">
              <i class="bi bi-circle-fill font-xssss dark-text "></i>
              <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                Cookies and Tracking :
              </h6>
            </li>
            <li>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                Onmax uses cookies and similar technologies to track user
                activity and store certain information. You can manage cookie
                preferences through your browser settings.
              </p>
            </li>
          </ul>
        </div>
        <div className="pt-md-4">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            2. How We Use Your Information
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            We use the information collected to:
          </p>
          <ul>
            <li className="d-flex align-items-center gap-2">
              <i class="bi bi-circle-fill font-xssss dark-text "></i>
              <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                Provide Services :
              </h6>
            </li>
            <li>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                To process your transactions, facilitate trading, and manage
                your account.
              </p>
            </li>
            <li className="d-flex align-items-center gap-2">
              <i class="bi bi-circle-fill font-xssss dark-text "></i>
              <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                Enhance User Experience :
              </h6>
            </li>
            <li>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                To improve our platform functionality and provide personalized
                recommendations.
              </p>
            </li>
            <li className="d-flex align-items-center gap-2">
              <i class="bi bi-circle-fill font-xssss dark-text "></i>
              <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                Communicate :
              </h6>
            </li>
            <li>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                To send important updates, newsletters, and promotional offers.
              </p>
            </li>
            <li className="d-flex align-items-center gap-2">
              <i class="bi bi-circle-fill font-xssss dark-text "></i>
              <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                Security and Fraud Prevention :
              </h6>
            </li>
            <li>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                To monitor and prevent fraudulent activities, protect your
                account, and comply with legal obligations.
              </p>
            </li>
          </ul>
        </div>
        <div className="pt-md-4">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            3. Information Sharing and Disclosure
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            Onmax does not sell or rent your personal information to third
            parties. However, we may share your data in the following
            circumstances:
          </p>
          <ul>
            <li className="d-flex align-items-center gap-2">
              <i class="bi bi-circle-fill font-xssss dark-text "></i>
              <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                Service Providers :
              </h6>
            </li>
            <li>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                We may share your information with trusted third-party service
                providers who assist us in delivering services, such as payment
                processors and analytics providers
              </p>
            </li>
            <li className="d-flex align-items-center gap-2">
              <i class="bi bi-circle-fill font-xssss dark-text "></i>
              <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                Legal Compliance :
              </h6>
            </li>
            <li>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                We may disclose your information if required by law, to comply
                with legal processes, or to protect our rights and the safety of
                our users.
              </p>
            </li>
            <li className="d-flex align-items-center gap-2">
              <i class="bi bi-circle-fill font-xssss dark-text "></i>
              <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                Business Transfers :
              </h6>
            </li>
            <li>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                In the event of a merger, acquisition, or sale of Onmax assets,
                your information may be transferred as part of the transaction.
              </p>
            </li>
          </ul>
        </div>
        <div className="pt-md-4">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            4. Security of Your Information
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            Onmax employs industry-standard security measures to protect your
            personal and financial information from unauthorized access,
            alteration, or disclosure. While we strive to protect your data, no
            security system is entirely foolproof, and we cannot guarantee
            absolute security.
          </p>
        </div>
        <div className="pt-md-4">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            5. Your Rights and Choices
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            You have the following rights regarding your personal information:
          </p>
          <ul>
            <li className="d-flex align-items-center gap-2">
              <i class="bi bi-circle-fill font-xssss dark-text "></i>
              <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                Access and Correction :
              </h6>
            </li>
            <li>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                You can access and update your personal information by logging
                into your account settings.
              </p>
            </li>
            <li className="d-flex align-items-center gap-2">
              <i class="bi bi-circle-fill font-xssss dark-text "></i>
              <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                Data Portability :
              </h6>
            </li>
            <li>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                You may request a copy of the data we hold about you in a
                structured, machine-readable format
              </p>
            </li>
            <li className="d-flex align-items-center gap-2">
              <i class="bi bi-circle-fill font-xssss dark-text "></i>
              <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                Deletion :
              </h6>
            </li>
            <li>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                You can request the deletion of your personal data, subject to
                any legal requirements or legitimate business purposes.
              </p>
            </li>
            <li className="d-flex align-items-center gap-2">
              <i class="bi bi-circle-fill font-xssss dark-text "></i>
              <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                Marketing Preferences :
              </h6>
            </li>
            <li>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                You can opt-out of marketing communications by following the
                unsubscribe instructions in any emails or by adjusting your
                account settings.
              </p>
            </li>
          </ul>
        </div>
        <div className="pt-md-4">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            7. Third-Party Links
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            Onmax may contain links to third-party websites or services. We are
            not responsible for the privacy practices of these external sites.
            We recommend reviewing their privacy policies before sharing any
            personal information.
          </p>
        </div>
        <div className="pt-md-4">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            8. Children’s Privacy
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            Onmax is not intended for individuals under the age of 18. We do not
            knowingly collect personal information from children. If you believe
            that we have inadvertently collected information from a minor,
            please contact us immediately, and we will take appropriate steps to
            remove the data.
          </p>
        </div>
        <div className="pt-md-4">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            9. Changes to This Privacy Policy
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with the updated effective date. We
            encourage you to review this policy periodically to stay informed
            about how we protect your information.
          </p>
        </div>
        <div className="pt-md-4">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            10. Contact Us
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            If you have any questions or concerns about this Privacy Policy or
            how we handle your personal information, please contact us at:
          </p>
          <p className="rajdhani-600 dark-text  text-gray-700 d-flex gap-2 ">
            <span className="h6">Email :</span>{" "}
            <a
              href="mailto:support@onmax.com"
              className="rajdhani-600 dark-text  font-sm   text-gray-70"
            >
              support@onmax.com
            </a>
          </p>
        </div>
        <div className="pt-md-4">
          <h6 className="dark-text">
            By using Onmax, you agree to the terms outlined in this Privacy
            Policy. If you do not agree with these terms, please discontinue use
            of the platform.
          </h6>
        </div>
      </div>
      <Footer/>
    </>
  );
}
