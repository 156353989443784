import React from "react";
import Sidebar from "../Coman/Sidebar";

export default function StakingReward() {
  return (
    <>
      <Sidebar />
      <div className="dash-main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="card bg-transparent border-0 pt-4 pb-3 px-0">
              <div className="card-body p-0 border-0 shadown-none d-flex flex-row mb-2">
                <h5 className="text-gray-900 dark-text font-xl dark-text rajdhani-600 mb-0 lh-24">
                  Staking Reward
                </h5>
              </div>
              <div className="card-body p-0 mt-3">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead className="thead-light bg-gray-200 text-gray-900 dark-text-black rounded-6 ovh light-bg">
                      <tr>
                        <th scope="col" />
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Projects
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Volumn
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Market
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Price
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Owner
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Assets
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row"></th>
                        <td>
                          <div className="d-flex flex-row gap-2">
                          
                          1
                          </div>
                        </td>
                        <td>
                          <h6 className="rajdhani-600 mb-0">4,881.65 ETH</h6>
                        </td>
                        <td>
                          <h6 className="rajdhani-600 mb-0">148,577.35 ETH</h6>
                        </td>
                        <td>
                          <h6 className="rajdhani-600 mb-0">24.49 ETH</h6>
                        </td>
                        <td>
                          <h6 className="rajdhani-600 mb-0">4,322</h6>
                        </td>
                        <td>
                          <h6 className="text-success rajdhani-600 m-0">
                            49.56 %
                          </h6>
                        </td>
                      </tr>
                    
                      <tr>
                        <th scope="row">6</th>
                        <td>
                          <div className="d-flex flex-row gap-2">
                            <figure className="avater mb-0">
                              <img
                                src="assets/images/n-2.png"
                                alt="user"
                                className="w-40"
                              />
                            </figure>
                            <h6 className="rajdhani-600 py-1 lh-28 m-0">
                              Ape Kennel Club
                            </h6>
                          </div>
                        </td>
                        <td>
                          <h6 className="rajdhani-600 mb-0">4,445.65 ETH</h6>
                        </td>
                        <td>
                          <h6 className="rajdhani-600 mb-0">148,577.35 ETH</h6>
                        </td>
                        <td>
                          <h6 className="rajdhani-600 mb-0">4.78 ETH</h6>
                        </td>
                        <td>
                          <h6 className="rajdhani-600 mb-0">4,322</h6>
                        </td>
                        <td>
                          <h6 className="text-success rajdhani-600 m-0">
                            49.56 %
                          </h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="card bg-transparent border-0 pt-3 pb-3 px-0">
              <div className="card-body p-0 mt-3">
                <div className="row">
                  <div className="col-12 justify-content-center">
                    <nav className="mt-4 mb-4">
                      <ul className="pagination pagination-lg justify-content-center">
                        <li className="page-item">
                          <a
                            className="page-link rajdhani-700 font-sm"
                            href="#"
                          >
                            Prev
                          </a>
                        </li>
                        <li className="page-item active">
                          <a
                            className="page-link rajdhani-700 font-sm "
                            href="#"
                          >
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link rajdhani-700 font-sm"
                            href="#"
                          >
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link rajdhani-700 font-sm"
                            href="#"
                          >
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link rajdhani-700 font-sm"
                            href="#"
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
}
