import React from "react";

export default function EcosystemHero() {
  return (
    <>
      <div
        className="banner-wrapper style17 pt--lg-100 bg-image-cover dark-bg3 bg-image-center bg-no-repeat"
        style={{ backgroundImage: 'url("assets/images/nft/bg-371.png")' }}
      >
        <div className="container-large pt-4 pb-lg-5">
          <div className="row pt-2 pb-lg-4 pb-2 justify-content-between">
            <div className="col-12 posr">
              <h2 className="display-size lh-1 rajdhani-700 text-gray-900 dark-textwhite">
                Explore Onmax Ecosystem
              </h2>
              <p className="rajdhani-600 dark-text ps-md-2 font-md lh-32 text-gray-900 w-lg-75 mt-5 pe-lg-5">
                The Onmax Ecosystem is designed to create an interconnected
                world of decentralized applications (dApps), services, and tools
                to empower users in enhancing their experience.
              </p>
            </div>
          </div>
     
        </div>
      </div>
    </>
  );
}
