import logo from "./logo.svg";
import "./App.css";
import Index from "./Componant/Home/Index";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Governance from "./Componant/Governance/Governance";
import Farming from "./Componant/Farming/Farming";
import Trading from "./Componant/Trading/Trading";
import NFTs from "./Componant/NFTs/NFTs";
import AboutUs from "./Componant/AboutUs/AboutUs";
import Bots from "./Componant/Bots/Bots";
import Community from "./Componant/Community/Community";
import Faq from "./Componant/Faq/Faq";
import Ecosystem from "./Componant/Ecosystem/Ecosystem";

import Dashboard from "./Componant/Pages/Dashboard/Dashboard";
import Stake from "./Componant/Pages/Stake/Stake";
import Staking from "./Componant/Staking/Staking";
import Locker from "./Componant/Pages/Locker/Locker";
import StakingReward from "./Componant/Pages/StakingReward";
import AggregatorRanks from "./Componant/Pages/AggregatorRanks";
import SuperReward from "./Componant/Pages/SuperReward";
import ValidatorReward from "./Componant/Pages/ValidatorReward";
import ColdReward from "./Componant/Pages/ColdReward";
import RankReward from "./Componant/Pages/RankReward";
import Ledger from "./Componant/Pages/Ledger";
import Unstake from "./Componant/Pages/Unstake";
import PrivacyPolicy from "./Componant/PrivacyPolicy";
import TermsofUse from "./Componant/TermsofUse";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/governance" element={<Governance />} />
        <Route path="/farming" element={<Farming />} />
        <Route path="/trading" element={<Trading />} />
        <Route path="/nfts" element={<NFTs />} />
        <Route path="/bots" element={<Bots />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/community" element={<Community />} />
        <Route path="/staking" element={<Staking />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/ecosystem" element={<Ecosystem />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsofUse />} />
        



        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/stake" element={<Stake />} />
        <Route path="/locker" element={<Locker />} />
        <Route path="/staking-reward" element={<StakingReward />} />
        <Route path="/aggregator-ranks" element={<AggregatorRanks />} />
        <Route path="/super-reward" element={<SuperReward />} />
        <Route path="/validator-reward" element={<ValidatorReward />} />
        <Route path="/cold-reward" element={<ColdReward />} />
        <Route path="/rank-reward" element={<RankReward />} />
        <Route path="/ledger" element={<Ledger/>} />
        <Route path="/unstake" element={<Unstake/>} />



      </Routes>
    </BrowserRouter>
  );
}

export default App;
