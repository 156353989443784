import React from "react";

export default function BotsHero() {
  return (
    <div>
      <div
        className="banner-wrapper style13 bg-image-none-dark py-4 dark-bg pt-100 bg-image-top bg-no-repeat"
        style={{ backgroundImage: "url(assets/images/bg-30.jpg)" }}
      >
        <div className="container-wide py-xl-5">
          <div className="row justify-content-between">
            <div className="col-lg-6 col-md-6 mt-md-4 md-mb-3">
              <h1 className="display4-size lh-1 text-gray-900 dark-text rajdhani-700">
                Unleash the Power Automated Trading
              </h1>
              <p className="rajdhani-600 dark-text h6 lh-28 text-gray-700 w-xl-75 mt-2">
                Experience seamless, 24/7 automated trading with Onmax Bots,
                designed to optimize your strategies and boost your profits
                effortlessly.
              </p>
              <div className="d-flex flex-row gap-3 mt-4 pt-3">
                <a
                  href="#"
                  className="btn btn-lg text-white rajdhani-600 rounded-6 bg-gray-900 border-dark border-2 px-lg-5 font-sm text-uppercase"
                >
                  Purchase Bot
                </a>
                <a
                  href="#"
                  className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 px-lg-5 font-sm text-uppercase"
                >
                  How it works
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 ps-md-4 posr md-mt-4">
              <img
                src="assets/images/bots/botshero.png"
                alt="banner"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
