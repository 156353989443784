import React from 'react'
import Header from '../Coman/Header'
import StakingHero from './StakingHero'
import SCreate from './SCreate'
import StakingYield from './StakingYield'
import Stakingcrypto from './Stakingcrypto'
import StaExpertize from './StaExpertize'
import Footer from '../Coman/Footer'

export default function Staking() {
  return (
    <div>
        <Header/>
        <StakingHero/>
        <SCreate/>
        <StakingYield/>
        {/* <StaExpertize/> */}
        <Stakingcrypto/>
        <Footer/>
    </div>
  )
}
