import React from "react";

export default function NFTsFaq() {
  return (
    <div>
      <div className="faq-wrapper pb-100 ">
        <div className="container">
          <div className="row">
            <div className="col-12 page-nav d-flex mb-5">
              <h2 className="text-dark-900 dark-text lh-2 m-0 rajdhani-600 h1">
                NFTs
              </h2>
              <i className="bi-arrow-up-right text-dark-900 dark-text ms-auto h4" />
            </div>
            <div className="col-12">
              <div
                className="accordion accordion-flush rounded-10 ovh dark-card"
                id="accordionFlushExample1"
              >
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-heading6">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse6"
                      aria-expanded="false"
                      aria-controls="flush-collapse6"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        1. What role do NFTs play on Onmax?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapse6"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading6"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Onmax NFTs offer unique utilities, including access to
                        exclusive features like trading bots, community
                        engagement, governance participation, and PLUS
                        Subscriptions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-heading6">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse7"
                      aria-expanded="false"
                      aria-controls="flush-collapse7"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        2. How do Onmax NFTs integrate with trading bots?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapse7"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading7"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Each Onmax NFT is linked to specific bots, with varying
                        profit margins and caps, allowing users to maximize
                        their trading potential based on the NFT they own.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-heading8">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse8"
                      aria-expanded="false"
                      aria-controls="flush-collapse8"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        3. Can Onmax NFTs be traded or sold?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapse8"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading8"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Yes, Onmax NFTs can be traded or sold on secondary
                        markets, offering users the opportunity to profit from
                        their appreciation over time.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-heading9">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse9"
                      aria-expanded="false"
                      aria-controls="flush-collapse9"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        4. What benefits do Onmax NFTs offer besides trading?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapse9"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading9"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Onmax NFTs provide additional benefits, including
                        community access, exclusive trading signals, governance
                        participation, and higher yield potential through
                        integrated DeFi features.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-heading10">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse10"
                      aria-expanded="false"
                      aria-controls="flush-collapse10"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        5. Are there different tiers of Onmax NFTs?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapse10"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading10"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Yes, Onmax NFTs come in different collections, each with
                        unique utilities and varying levels of profit capping,
                        tailored to different user preferences and strategies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
