import React from 'react'
import Header from '../../Coman/Header'
import LokerBox from './LokerBox'
import Sidebar from '../../Coman/Sidebar'

export default function Locker() {
  return (
    <div>
        <Sidebar/>
        <LokerBox/>
    </div>
  )
}
