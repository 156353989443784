import React from "react";

export default function BFuture() {
  return (
    <div>
      <div>
        <div className="feature-wrapper dark-bg3 pt-100 pb-100 posr border-bottom border-bluelight dark-border2">
          <div className="container">
            <div className="row">
              <div className="col-md-10">
                <h2 className="display-size lh-2 mb-0 text-gray-900 rajdhani-600 dark-text">
                  Stop stressing & start profiting with Onmax’s highly efficient
                  trading bots!
                </h2>
              </div>
              <div className="col-md-2 mt-auto text-md-end text-start mb-3 h-45 ovh posr">
                <i className="bi-arrow-up-right bg-gray-900 text-white h1 lh-32 m-0" />
              </div>
            </div>
          </div>
        </div>
        <div className="feature-wrapper style2 posr border-bottom border-bluelight dark-border2 dark-bg3">
          <div className="container">
            <div className="row ms-n3 me-n3">
              <div className="col-md-3 px-0">
                <div className="feature-div border-end border-start border-bluelight dark-border2">
                  <h4 className="display1-size text-gray-900 rajdhani-700 dark-text">
                    01
                  </h4>
                  <h2 className="rajdhani-600 dark-text h4 mt-2 mb-2">
                    {" "}
                    Purchase Bot
                  </h2>
                  <p className="pe-3 text-gray-600 dark-text rajdhani-500 font-sm mb-0">
                    Select one of the specialized Onmax Bot designed to match
                    your unique trading strategy & capital preferences.
                  </p>
                </div>
              </div>
              <div className="col-md-3 px-0">
                <div className="feature-div border-end border-start border-bluelight dark-border2">
                  <h4 className="display1-size text-gray-900 rajdhani-700 dark-text">
                    02
                  </h4>
                  <h2 className="rajdhani-600 dark-text h4 mt-2 mb-2">
                    Set Risk Strategy
                  </h2>
                  <p className="pe-3 text-gray-600 dark-text rajdhani-500 font-sm mb-0">
                    Activate your bot & customize your risk parameters to ensure
                    they align with your investment objectives.
                  </p>
                </div>
              </div>
              <div className="col-md-3 px-0">
                <div className="feature-div border-end border-start border-bluelight dark-border2">
                  <h4 className="display1-size text-gray-900 rajdhani-700 dark-text">
                    03
                  </h4>
                  <h2 className="rajdhani-600 dark-text h4 mt-2 mb-2">
                    Deploy Bot
                  </h2>
                  <p className="pe-3 text-gray-600 dark-text rajdhani-500 font-sm mb-0">
                    Activate your Bot to start executing trades automatically
                    based on your predefined strategy & market conditions.
                  </p>
                </div>
              </div>
              <div className="col-md-3 px-0">
                <div className="feature-div border-end border-start border-bluelight dark-border2">
                  <h4 className="display1-size text-gray-900 rajdhani-700 dark-text">
                    04
                  </h4>
                  <h2 className="rajdhani-600 dark-text h4 mt-2 mb-2">
                    Start Profiting
                  </h2>
                  <p className="pe-3 text-gray-600 dark-text rajdhani-500 font-sm mb-0">
                    Experience a hands-free trading approach as your bot
                    diligently works to generate potential profits in the
                    dynamic market.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
