import React from "react";

export default function SCreate() {
  return (
    <>
      <div className="howitwork-wrapper py-5">
        <div className="container-wide py-lg-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center justify-content-center gap-2 pb-75">
              <h2 className="text-gray-900 rajdhani-600 h1 mb-0 dark-text">
                Why Stake with Onmax?
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 lg-mb-3 howitwork-div d-flex flex-column justify-content-center text-center">
              <div className="rounded-pill new-pink-bg btn btn-icon btn-xl mx-auto  text-white font-lg  ">
                <img
                  src="assets/images/staking/high_yield_rewards.svg"
                  height={30}
                  width={30}
                  alt=""
                />
              </div>
              <h6 className="rajdhani-600 mt-3 mb-2 h5 text-gray-900 dark-text">
                High-Yield Rewards
              </h6>
              <p className="text-gray-600 dark-text rajdhani-600 mt-0 font-sm px-xl-3 lh-26 mb-0">
                Onmax offers competitive staking rewards, giving you the
                opportunity to maximize your earnings by staking your digital
                assets. Enjoy consistent payouts that grow with the time you
                stake.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 lg-mb-3 howitwork-div d-flex flex-column justify-content-center text-center">
              <div className="rounded-pill new-pink-bg btn btn-icon btn-xl mx-auto  text-white font-lg  ">
                <img
                  src="assets/images/staking/easy_setup.svg"
                  height={30}
                  width={30}
                  alt=""
                />
              </div>
              <h6 className="rajdhani-600 mt-3 mb-2 h5 text-gray-900 dark-text">
                Easy Setup
              </h6>
              <p className="text-gray-600 dark-text rajdhani-600 mt-0 font-sm px-xl-3 lh-26 mb-0">
                Staking with Onmax is simple and straightforward. Choose the
                asset you want to stake, deposit it into your staking wallet,
                and start earning rewards right away without any complicated
                processes.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 lg-mb-3 howitwork-div d-flex flex-column justify-content-center text-center">
              <div className="rounded-pill new-pink-bg btn btn-icon btn-xl mx-auto  text-white font-lg  ">
                <img
                  src="assets/images/staking/flexible_lock_periods.svg"
                  height={30}
                  width={30}
                  alt=""
                />
              </div>
              <h6 className="rajdhani-600 mt-3 mb-2 h5 text-gray-900 dark-text">
                Flexible Lock Periods
              </h6>
              <p className="text-gray-600 dark-text rajdhani-600 mt-0 font-sm px-xl-3 lh-26 mb-0">
                Whether you prefer short-term or long-term staking, Onmax offers
                flexible lock periods to fit your investment strategy. Choose
                from different timeframes and stake at your convenience.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 lg-mb-3 howitwork-div d-flex flex-column justify-content-center text-center">
              <div className="rounded-pill new-pink-bg btn btn-icon btn-xl mx-auto  text-white font-lg  ">
                <img
                  src="assets/images/staking/secure _transparent.svg"
                  height={30}
                  width={30}
                  alt=""
                />
              </div>
              <h6 className="rajdhani-600 mt-3 mb-2 h5 text-gray-900 dark-text">
                Secure & Transparent
              </h6>
              <p className="text-gray-600 dark-text rajdhani-600 mt-0 font-sm px-xl-3 lh-26 mb-0">
                We prioritize the security of your assets. Onmax Staking is
                built on a decentralized framework that ensures transparency and
                safety, giving you peace of mind while your assets work for you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
