import React from "react";

export default function Tokan() {
  return (
    <div>
      <div className="feature-wrapper black-bg  ">
        <div className="container pt-100 pb-100">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12  md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 ">
                OMEmpowers Onmax Governance
              </h2>
              <h6 className="text-gray-700 dark-text rajdhani-500 lh-30">
                OMtoken empowering users to actively participate in Onmax
                Governance and shape the future of the ecosystem. As the native
                token, OMgrants holders the ability to propose changes, join
                discussions, and vote on key decisions that influence the
                platform’s development.{" "}
              </h6>
              <h6 className="text-gray-700 dark-text rajdhani-500 lh-30">
                By staking OM, community members can suggest improvements,
                collaborate with other governing members, and ensure that the
                ecosystem grows in alignment with collective interests. OM
                enables a truly democratic process, where every holder plays a
                crucial role in the governance of Onmax.
              </h6>
              <h6 className="text-gray-900 rajdhani-600 dark-text">
                Contract Address:{" "}
                <a
                  href="#"
                  className="dark-text text-black"
                  style={{ borderBottom: "1px solid black" }}
                >
                  --
                </a>{" "}
              </h6>
              <div className="d-flex flex-row gap-3 mt-4">
                <a
                  href="#"
                  className="btn btn-lg text-white rajdhani-600 rounded-6 bg-gray-900 light-bg border-dark border-2 px-md-5 font-sm text-uppercase"
                >
                  Buy OM
                </a>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 ms-auto">
              <img
                src="assets/images/gov/omp_empowers_onmax.png"
                alt="banner"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
