import React from "react";

export default function Create() {
  return (
    <div>
      <div className="feature-wrapper black-bg ">
        <div className="container pt-100">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <img
                src="assets/images/farm/donts_just_hold_crypto_utilize_it_section.png"
                alt="banner"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 col-md-12 ms-auto md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 pe-lg-5">
              Don’s just hold crypto, utilize it!
              </h2>
              <h6 className="text-gray-700 dark-text rajdhani-500 lh-30 pe-lg-5 me-lg-5">
              Use your existing crypto assets to provide liquidity & unlock massive rewards from Onmax yield farming. 
              </h6>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
