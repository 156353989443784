import React from "react";

export default function CommunityCont() {
  return (
    <div>
      <div className="text-wrapper pt-75 pb-75 " style={{background:"linear-gradient(45deg, #fa43fd, transparent)"}}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 text-center">
              <h3 className="display1-size text-gray-900 rajdhani-600 mb-0"></h3>

              <h3 className="display1-size text-gray-900 rajdhani-600">
                <span
                  className="bg-image-contain bg-no-repeat bg-image-center"
                  // style={{
                  //   backgroundImage: "url(assets/images/text-bg-1.png)",
                  // }}
                ></span>{" "}
                Join a vibrant network of traders, investors, and crypto
                enthusiasts dedicated to sharing knowledge, strategies, and
                insights. Our community is your go-to hub for connecting with
                like-minded individuals who are passionate about decentralized
                finance and innovative trading solutions!
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
