import React from "react";

export default function StakingFaq() {
  return (
    <div>
      <div className="faq-wrapper pb-100 ">
        <div className="container">
          <div className="row">
            <div className="col-12 page-nav d-flex mb-5">
              <h2 className="text-dark-900 dark-text lh-2 m-0 rajdhani-600 h1">
                Staking
              </h2>
              <i className="bi-arrow-up-right text-dark-900 dark-text ms-auto h4" />
            </div>
            <div className="col-12">
              <div
                className="accordion accordion-flush rounded-10 ovh dark-card"
                id="accordionFlushExample1"
              >
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-heading6">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse6"
                      aria-expanded="false"
                      aria-controls="flush-collapse6"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        1. What is staking on Onmax?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapse6"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading6"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Staking on Onmax allows users to lock up their tokens in
                        smart contracts to earn passive rewards over time,
                        contributing to the security and efficiency of the
                        blockchain network.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-heading6">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse7"
                      aria-expanded="false"
                      aria-controls="flush-collapse7"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        2. How do I start staking on Onmax?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapse7"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading7"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        To start staking, users need to select a staking pool,
                        lock up their tokens for a specified period, and earn
                        rewards in return.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-heading8">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse8"
                      aria-expanded="false"
                      aria-controls="flush-collapse8"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        3. What rewards can I earn from staking?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapse8"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading8"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Staking rewards typically come in the form of Onmax's
                        native token (OM), though other tokens may be offered
                        depending on the staking pool.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-heading9">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse9"
                      aria-expanded="false"
                      aria-controls="flush-collapse9"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        4. Is there a minimum amount required to stake on Onmax
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapse9"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading9"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Yes, each staking pool may have its own minimum staking
                        requirements, which will be listed before you commit
                        your tokens.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-heading10">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse10"
                      aria-expanded="false"
                      aria-controls="flush-collapse10"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        5. Can I unstake my tokens anytime on Onmax?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapse10"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading10"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Staking periods vary by pool. Some pools allow flexible
                        staking with no lock-in periods, while others require
                        you to keep your tokens locked for a fixed duration.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
