import React from "react";

export default function CommunityHero() {
  return (
    <div>
      <div
        className="banner-wrapper pt-100 bg-image-cover"
        style={{ backgroundImage: "url(assets/images/bg-20.png)" }}
      >
        <div className="banner-pattern top-0" style={{ zIndex: -1 }}>
          <img
            src="assets/images/bg-41.png"
            alt="pattern"
            className="ani4 pattern1 w-300"
          />
        </div>
        <div className="container md-pt-5">
          <div className="row h-100 d-flex justify-content-between pb-lg-5  align-items-center">
            <div className="col-lg-7 col-md-6 col-xs-12 my-md-auto mt-md-5">
              <h1 className="rajdhani-700 display5-size text-gray-900 dark-text lh-1">
                Be Part of Something Bigger
              </h1>
              <p className="rajdhani-600 dark-text ps-md-2 font-md lh-32 text-gray-900 w-lg-75 mt-2 pe-lg-5">
                At Onmax, we believe that collaboration & community are key to
                success in the ever-evolving world of cryptocurrency. Join us
                today and be part of a supportive, innovative, and engaging
                environment that empowers you to achieve your trading goals!
              </p>
<h6 className="rajdhani-600 h5">10k+ Members 
</h6>
            </div>
            <div className="col-lg-5 col-md-6 px-lg-0">
              <img
                src="assets/images/com/hero_section_image.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
