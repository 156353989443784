import React from "react";

export default function FarmingFaq() {
  return (
    <div>
      <div className="faq-wrapper pb-100 ">
        <div className="container">
          <div className="row">
            <div className="col-12 page-nav d-flex mb-5">
              <h2 className="text-dark-900 dark-text lh-2 m-0 rajdhani-600 h1">
                Yield Farming
              </h2>
              <i className="bi-arrow-up-right text-dark-900 dark-text ms-auto h4" />
            </div>
            <div className="col-12">
              <div
                className="accordion accordion-flush rounded-10 ovh dark-card"
                id="accordionFlushExample1"
              >
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        1. What is yield farming on Onmax?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Onmax yield farming allows users to provide liquidity to
                        the platform’s pools in exchange for rewards, typically
                        in the form of native tokens or other cryptocurrencies.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border bg-transparent collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        2. How can I participate in yield farming on Onmax?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        To participate in yield farming, users need to deposit
                        supported tokens into Onmax's liquidity pools. In
                        return, they earn rewards based on the pool's
                        performance and their contribution.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border bg-transparent collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        3. What are the risks of yield farming on Onmax?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Risks include impermanent loss, fluctuating token
                        prices, and potential smart contract vulnerabilities. It
                        is crucial to understand these risks before investing in
                        yield farming.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border bg-transparent collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        4 What types of rewards can I earn from Onmax yield
                        farming?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Users can earn various rewards, including Onmax’s native
                        token (OM) and other partner tokens, depending on the
                        liquidity pool they choose to participate in.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border bg-transparent collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        5. How often are rewards distributed in yield farming?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Rewards are typically distributed at regular intervals,
                        such as daily or weekly, depending on the specific yield
                        farming pool.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
