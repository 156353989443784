import React from "react";

export default function TraMain() {
  return (
    <div>
      <div className="feature-wrapper black-bg ">
        <div className="container pt-100">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <img
                src="assets/images/tra/section-image-file.png"
                alt="banner"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 col-md-12 ms-auto md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 pe-lg-5">
              Trading made easy & efficient!
              </h2>
              <h6 className="text-gray-700 dark-text rajdhani-500 lh-30 pe-lg-5 me-lg-5">
              Onmax's advanced bots automate complex trading strategies, making it easy for users to execute trades effortlessly. With real-time optimization and data-driven insights, trading becomes both efficient and highly profitable.

              </h6>
              <h6 className="text-gray-900 mb-1 mt-4 dark-text rajdhani-600 d-flex gap-2 lh-30">
                {" "}
                <i className="new-pink1 font-xs bi-check-circle-fill" />
                Spot & Options Trading
              </h6>
              <h6 className="text-gray-900 mb-1 dark-text rajdhani-600 d-flex gap-2 lh-30">
                {" "}
                <i className="new-pink1 font-xs bi-check-circle-fill" />
                Arbitrage Trading
              </h6>
              <h6 className="text-gray-900 mb-1 dark-text rajdhani-600 d-flex gap-2 lh-30">
                {" "}
                <i className="new-pink1 font-xs bi-check-circle-fill" />
                Binary Trading 

              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
