import React from 'react'
import Header from '../Coman/Header'
import GovernanceHero from './GovernanceHero'
import Gmain from './Gmain'
import Gworks from './Gworks'
import GBlog from './GBlog'
import GFaq from './GFaq'
import GSubsribe from './GSubsribe'
import Footer from '../Coman/Footer'
import Tokan from './Tokan'

export default function Governance() {
  return (
    <div>
        <Header/>
        <GovernanceHero/>
        <Gmain/>
        <GBlog/>
        <Tokan/>

        <Gworks/>
        <GFaq/>
        <GSubsribe/>
        <Footer/>
    </div>
  )
}
